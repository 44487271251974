/* eslint-disable */ 
import userListStore from '@/store/user/user-list-store';
import userFormStore from '@/store/user/user-form-store';
import userViewStore from '@/store/user/user-view-store';
import userDestroyStore from '@/store/user/user-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: userListStore,
    form: userFormStore,
    view: userViewStore,
    destroy: userDestroyStore
  },
};
