/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSettings)

export class SettingService {
  static async updateGeneralSettings(settings) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SETTINGS_GENERAL_UPDATE($settings: SettingsInput!) {
          updateSettings(settings: $settings)
        }
      `,

      variables: {
        settings
      }
    })

    // return response.data.updateSettings
    if (response.data.updateSettings && response.data.updateSettings.status) {
      return response.data.updateSettings.result
    } else {
      console.error(response.data.updateSettings.error)
      throw response.data.updateSettings.error
    }
  }

  static async findGeneralSettings() {
    const response = await graphqlClient.query({
      query: gql`
        query SETTINGS_GENERAL_FIND {
          getSettings {
            whatsapp
            phoneNumber
            email
            address
            vat
            comm
            snapchat
            instagram
            appStore
            playStore
            androidVersion
            IOSVersion
          }
        }
      `
    })

    return response.data.getSettings
  }

  static async updateTermsAndConditions(body_html) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SAVE($body_html: LocalizationInput!) {
          saveTermsAndConditions(body_html: $body_html)
        }
      `,
      variables: {
        body_html
      }
    })

    // return response.data.updateTermsAndConditions
    if (response.data.saveTermsAndConditions) {
      return response.data.saveTermsAndConditions
    } else {
      console.error(response.data.saveTermsAndConditions)
      throw response.data.saveTermsAndConditions
    }
  }

  static async findTermsAndConditions() {
    const response = await graphqlClient.query({
      query: gql`
        query TERMS_AND_CONDITIONS_FIND {
          findTermsAndConditions {
            id
            body_html {
              en
              ar
            }
          }
        }
      `
    })

    return response.data.findTermsAndConditions
  }

  static async updatePrivacyPolicy(body_html) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SAVE($body_html: LocalizationInput!) {
          savePrivacyPolicy(body_html: $body_html)
        }
      `,
      variables: {
        body_html
      }
    })

    // return response.data.updatePrivacyPolicy
    if (response.data.savePrivacyPolicy) {
      return response.data.savePrivacyPolicy
    } else {
      console.error(response.data.updatePrivacyPolicy)
      throw response.data.updatePrivacyPolicy
    }
  }

  static async findPrivacyPolicy() {
    const response = await graphqlClient.query({
      query: gql`
        query PRIVACY_POLICY_FIND {
          findPrivacyPolicy {
            id
            body_html {
              en
              ar
            }
          }
        }
      `
    })

    return response.data.findPrivacyPolicy
  }
  static async find() {
    const response = await graphqlClient.query({
      query: gql`
        query {
          settingsFind {
            id
            minimumWalletBalance
            exchange_amount
            daily_purchase_limit
            emailTemplate
            IOSVersion
            androidVersion
          }
        }
      `
    })

    return response.data.settingsFind
  }
  static async save(settings) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SETTINGS_SAVE($settings: SettingsInput!) {
          settingsSave(settings: $settings)
        }
      `,
      variables: {
        settings
      }
    })

    return response.data.settingsSave
  }

  static async listGateways() {
    const response = await graphqlClient.query({
      query: gql`
        query {
          listPaymentGateways {
            gateway_id
            name_en
            name_ar
            paymentMethods {
              payment_id
              name_en
              name_ar
              isActive
            }
          }
        }
      `
    })

    return response.data.listPaymentGateways
  }

  static async toggleStatus(gatewayId, payments) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CHANGE_STATUS(
          $gateway_id: GatewayIdEnum!
          $payments: [ChangeStatusInput!]!
        ) {
          paymentMethodsChangeStatus(
            gateway_id: $gateway_id
            payments: $payments
          )
        }
      `,
      variables: {
        gateway_id: gatewayId,
        payments
      }
    })

    return response.data.paymentMethodsChangeStatus
  }

  static async listMemberships() {
    const response = await graphqlClient.query({
      query: gql`
        query {
          listMembershipDiscounts {
            id
            product_type
            adjustment_method
            min_discount
            max_discount
            target_amount
          }
        }
      `
    })

    return response.data.listMembershipDiscounts
  }

  static async updateMemberships(memberships) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SAVE($discounts: [MembershipDiscountInput!]!) {
          saveDiscountsChanges(discounts: $discounts)
        }
      `,
      variables: {
        discounts: memberships
      }
    })

    return response.data.paymentMethodsChangeStatus
  }
}
