import { appCheck } from '@/shared/firebase/firebase-init'
import { getApps } from 'firebase/app'
import { getToken } from 'firebase/app-check'
import { getAuth, getIdToken } from 'firebase/auth'
import Errors from '@/shared/error/errors'

export class AuthToken {
  static async get() {
    const currentUser = getAuth().currentUser
    if (getApps().length && currentUser) {
      const token = await getIdToken(currentUser, true)
      return token
    }
    return null
  }

  static async getAppCheckToken() {
    try {
      if (appCheck) return await getToken(appCheck, true)
      return null
    } catch (error) {
      Errors.handle(error)
    }
  }
}
