<template>
  <div>
    <div class="switch">
      <input
        id="language-toggle"
        class="check-toggle check-toggle-round-flat"
        type="checkbox"
        @click="doChangeLanguage()"
      />
      <label for="language-toggle"></label>
      <span :class="isArabic ? 'on' : 'off'">AR</span>
      <span :class="isArabic ? 'off' : 'on'">EN</span>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { setLanguageCode } from '@/locales/i18n'
import i18n from '@/vueI18n'

export default {
  name: 'app-toggle-language',
  setup() {
    const ar = ref(localStorage.getItem('language'))
    const value = ref(i18n.locale)
    return {
      ar,
      value
    }
  },
  data: () => ({
    isArabic: true
  }),
  created() {
    this.isArabic = localStorage.getItem('language') == 'ar'
  },
  methods: {
    doChangeLanguage() {
      const language = this.$i18n.locale == 'ar' ? 'en' : 'ar'

      this.$i18n.locale = language
      setLanguageCode(language)
      if (language == 'ar') {
        this.ar = true
        document.body.setAttribute('dir', 'rtl')
      } else {
        this.ar = false
        document.body.setAttribute('dir', 'ltr')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  margin: 0 5px;
}

.switch > span {
  position: absolute;
  top: 9px;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #d90416;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #fff;
}

.switch > span.on {
  left: 0;
  padding-left: 2px;
  color: #d90416;
}

.switch > span.off {
  right: 0;
  padding-right: 4px;
  color: #fff;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 99px;
  height: 35px;
  background-color: #d90416;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: '';
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #d90416;

  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

// input.check-toggle-round-flat:checked + label {
// }

input.check-toggle-round-flat:checked + label:after {
  margin-left: 44px;
}
</style>
