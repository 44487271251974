/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlNotify)

export class NotificationService {
  static async sendToUsers(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SEND($data: NotificationInput!) {
          sendToAll(data: $data)
        }
      `,

      variables: {
        data
      }
    })
    if (response.data.sendToAll && response.data.sendToAll.status) {
      return response.data.sendToAll.result
    } else {
      console.error(response.data.sendToAll.error)
      throw response.data.sendToAll.error
    }
  }

  static async sendToCustom(data, usersIds) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SEND($usersIds: [String!]!, $data: NotificationInput!) {
          sendToUsers(usersIds: $usersIds, data: $data)
        }
      `,
      variables: {
        data,
        usersIds
      }
    })
    // return response.data.sendNotificationToUsers
    if (response.data.sendToUsers && response.data.sendToUsers.status) {
      return response.data.sendToUsers.result
    } else {
      console.error(response.data.sendToUsers.error)
      throw response.data.sendToUsers.error
    }
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_FIND($notificationId: String!) {
          findNotificationById(notificationId: $notificationId) {
            id
            action
            body {
              en
              ar
            }
            image
            isRead
            isRemoved
            title {
              en
              ar
            }
            data
            userType
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        notificationId: id
      }
    })

    return response.data.findNotificationById
  }

  static async list(pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_LIST($pagination: PaginationInput!) {
          listNotifications(pagination: $pagination) {
            id
            action
            body {
              en
              ar
            }
            image
            isRead
            isRemoved
            title {
              en
              ar
            }
            data
            userType
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        pagination
      }
    })

    return response.data.listNotifications
  }

  static async listUserNotifications(userId, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_USER_NOTIFICATIONS(
          $userId: String!
          $pagination: PaginationInput!
        ) {
          listUserNotifications(userId: $userId, pagination: $pagination) {
            id
            image
            title
            body
            data
            action
            isRead
            isRemoved
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        userId,
        pagination
      }
    })

    return response.data.listUserNotifications
  }
  //#endregion
}
