/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlCategory)

export class CategoryService {
  //#region [ Mutation APIs ]
  /**
   * Create new category
   * - addCategory(name: NameInput!): JSON!
   * @param {Object} name
   * @param {String} name.en
   * @param {String} name.ar
   * @returns
   */
  static async createCategory(name) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE_CATEGORY($name: NameInput!) {
          addCategory(name: $name)
        }
      `,

      variables: {
        name
      }
    })

    if (response.data.addCategory && response.data.addCategory.status) {
      return response.data.addCategory.result
    } else {
      console.error(response.data.addCategory.error)
      throw response.data.addCategory.error
    }
  }

  /**
   * Update category
   * - editCategory(categoryId: String!, updateObject: JSON!): JSON!
   * @param {String} id - Category ID
   * @param {Object} name
   * @param {String} name.en
   * @param {String} name.ar
   * @returns
   */
  static async editCategory(id, name) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_UPDATE($categoryId: String!, $updateObject: JSON!) {
          editCategory(categoryId: $categoryId, updateObject: $updateObject)
        }
      `,

      variables: {
        categoryId: id,
        updateObject: { name }
      }
    })

    if (response.data.editCategory && response.data.editCategory.status) {
      return response.data.editCategory.result
    } else {
      console.error(response.data.editCategory.error)
      throw response.data.editCategory.error
    }
  }

  /**
   * Delete category by ID
   * - removeCategory(categoryId: String!): JSON!
   * @param {String} categoryId
   * @returns
   */
  static async removeCategory(categoryId) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_DESTROY($categoryId: String!) {
          removeCategory(categoryId: $categoryId)
        }
      `,

      variables: {
        categoryId
      }
    })

    if (response.data.removeCategory && response.data.removeCategory.status) {
      return response.data.removeCategory.result
    } else {
      console.error(response.data.removeCategory.error)
      throw response.data.removeCategory.error
    }
  }

  /**
   *
   * - setCategoryImage(categoryId: String!, image: String!): JSON!
   * @param {String} categoryId
   * @param {String} image
   */
  static async setCategoryImage(categoryId, image) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation SET_CATEGORY_IMAGE($categoryId: String!, $image: String!) {
          setCategoryImage(categoryId: $categoryId, image: $image)
        }
      `,

      variables: {
        categoryId,
        image
      }
    })

    if (
      response.data.setCategoryImage &&
      response.data.setCategoryImage.status
    ) {
      return response.data.setCategoryImage.result
    } else {
      console.error(response.data.setCategoryImage.error)
      throw response.data.setCategoryImage.error
    }
  }

  /**
   * Arrange categories by order
   * - arrangeCategories(categoriesIds: [String]!): JSON!
   * @param {String[]} categoriesIds
   * @returns {Promise<JSON>}
   */
  static async arrangeCategories(categoriesIds) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_ARRANGE($categoriesIds: [String]!) {
          arrangeCategories(categoriesIds: $categoriesIds)
        }
      `,

      variables: {
        categoriesIds
      }
    })

    if (
      response.data.arrangeCategories &&
      response.data.arrangeCategories.status
    ) {
      return response.data.arrangeCategories.result
    } else {
      console.error(response.data.arrangeCategories.error)
      throw response.data.arrangeCategories.error
    }
  }
  //#endregion

  //#region [ Query APIs ]

  /**
   * Find Category By ID
   * - findCategoryById(categoryId: String!): Category!
   * @param {String} id - Category ID
   * @returns
   */
  static async findCategoryById(id) {
    const response = await graphqlClient.query({
      query: gql`
        query CATEGORY_FIND($categoryId: String!) {
          findCategoryById(categoryId: $categoryId) {
            id
            name {
              en
              ar
            }
            image
            order
          }
        }
      `,

      variables: {
        categoryId: id
      }
    })

    return response.data.findCategoryById
  }

  /**
   * List all categories
   * - categories: [Category]!
   * @returns {Promise<Category[]>}
   */
  static async listCategories(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query CATEGORY_LIST(
          $filter: [FilterInput!]
          $orderBy: String
          $pagination: PaginationInput
        ) {
          categoryList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              parent_id
              name
              is_active
              position
              level
              product_count
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,
      variables: {
        orderBy: 'id',
        filter,
        pagination
      }
    })
    return response.data.categoryList
  }

  static async addCategories() {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation {
          addCategoriesToDatabase
        }
      `
    })
    return response.data.addCategoriesToDatabase
  }
  static async toggleStatus(id, enabled) {
    await graphqlClient.mutate({
      mutation: gql`
        mutation CATEGORY_CHANGE_STATUS($id: String!, $enabled: Boolean) {
          categoryChangeStatus(id: $id, enabled: $enabled)
        }
      `,
      variables: {
        id,
        enabled
      }
    })
  }
  //#endregion
}
