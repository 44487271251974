import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  categoryEdit: {
    id: 'categoryEdit',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.providerCategoryEditor
    ]
  },
  categoryRead: {
    id: 'categoryRead',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.providerCategoryEditor,
      roles.providerCategoryViewer
    ]
  }
}

export default Permissions
