/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'

const graphqlAdmin = GraphqlClient.initApolloClient(config.backendUrlAdmin)
const graphqlPortal = GraphqlClient.initApolloClient(config.backendUrlPortal)
const graphqlCustomer = GraphqlClient.initApolloClient(
  config.backendUrlCustomer
)

export class UserService {
  //#region [ Mutation APIs ]
  /**
   * Enable user in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  static async enableAdmins(ids) {
    return this._changeAdminsStatus(ids, false)
  }

  static async enableCustomers(ids) {
    return this._changeCustomersStatus(ids, false)
  }

  /**
   * Disable user in authentication table and firestore database
   * @param {String[]} ids
   * @returns
   */
  static async disableAdmins(ids) {
    return this._changeAdminsStatus(ids, true)
  }

  static async disableCustomers(ids) {
    return this._changeCustomersStatus(ids, true)
  }

  static async _changeAdminsStatus(ids, disabled) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation ADMIN_CHANGE_STATUS($ids: [String!]!, $disabled: Boolean) {
          adminChangeStatus(ids: $ids, disabled: $disabled)
        }
      `,

      variables: {
        ids,
        disabled
      }
    })

    return response.data.adminChangeStatus
  }

  static async _changeAdminsStatus(ids, disabled) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation ADMIN_CHANGE_STATUS($ids: [String!]!, $disabled: Boolean) {
          adminChangeStatus(ids: $ids, disabled: $disabled)
        }
      `,

      variables: {
        ids,
        disabled
      }
    })
    return response.data.adminChangeStatus
    // if (response.data.adminChangeStatus && response.data.adminChangeStatus.status) {
    //   return response.data.adminChangeStatus.result
    // } else {
    //   console.error(response.data.adminChangeStatus.error)
    //   throw response.data.adminChangeStatus.error
    // }
  }
  static async _changeCustomersStatus(ids, disabled) {
    const response = await graphqlCustomer.mutate({
      mutation: gql`
        mutation CHANGE_STATUS($ids: [String!]!, $disabled: Boolean) {
          customerChangeStatus(ids: $ids, disabled: $disabled)
        }
      `,

      variables: {
        ids,
        disabled
      }
    })

    return response.data.customerChangeStatus
    // if (response.data.customerChangeStatus && response.data.customerChangeStatus.status) {
    //   return response.data.customerChangeStatus.result
    // } else {
    //   console.error(response.data.customerChangeStatus.error)
    //   throw response.data.customerChangeStatus.error
    // }
  }

  /**
   * Set page access permissions for the admin
   * - setAdminAccess(adminId: String!, pagesAccess: [String]!): JSON!
   * @param {String} id
   * @param {String[]} pagesAccess
   * @returns
   */
  static async setAdminAccess(id, pagesAccess) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation USER_CHANGE_ROLES($adminId: String!, $pagesAccess: [String]!) {
          setAdminAccess(adminId: $adminId, pagesAccess: $pagesAccess)
        }
      `,

      variables: {
        adminId: id,
        pagesAccess
      }
    })

    // return response.data.setAdminAccess
    if (response.data.setAdminAccess && response.data.setAdminAccess.status) {
      return response.data.setAdminAccess.result
    } else {
      console.error(response.data.setAdminAccess.error)
      throw response.data.setAdminAccess.error
    }
  }

  /**
   * Create new admin
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.password
   * @param {String} data.userName
   * @param {String} data.phoneNumber
   * @param {String[]} data.pagesAccess
   * @param {String} data.avatar
   * @param {'en'|'ar' } data.lang
   * @returns
   */
  static async create(data) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation ADMIN_CREATE($data: AdminInput!) {
          adminCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.adminCreate
  }

  static async createPortal(data) {
    const response = await graphqlPortal.mutate({
      mutation: gql`
        mutation CREATE($data: PortalAdminInput!) {
          createPortalAdmin(data: $data)
        }
      `,
      variables: {
        data
      }
    })

    return response.data.createPortalAdmin
  }

  /**
   * Update admin
   * @param {String} id
   * @param {Object} data
   * @param {String} data.email
   * @param {String} data.userName
   * @param {String} data.phoneNumber
   * @param {String[]} data.pagesAccess
   * @param {String} data.avatar
   * @param {'en'|'ar' } data.lang
   * @returns
   */
  static async edit(id, data) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation ADMIN_UPDATE($id: String!, $data: AdminUpdateInput!) {
          adminUpdate(id: $id, data: $data)
        }
      `,

      variables: {
        id,
        data
      }
    })

    // if (response.data.editAdmin && response.data.editAdmin.status) {
    //   return response.data.editAdmin.result
    // } else {
    //   console.error(response.data.editAdmin.error)
    //   throw response.data.editAdmin.error
    // }
    return response.data.adminUpdate
  }
  static async editPortal(data) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation ADMIN_UPDATE($data: IamEditInput!) {
          adminUpdate(data: $data)
        }
      `,

      variables: {
        data
      }
    })

    // if (response.data.editAdmin && response.data.editAdmin.status) {
    //   return response.data.editAdmin.result
    // } else {
    //   console.error(response.data.editAdmin.error)
    //   throw response.data.editAdmin.error
    // }
    return response.data.adminUpdate
  }

  static async removeUsers(id) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation ADMIN_DESTROY($id: String!) {
          adminDestroy(id: $id)
        }
      `,

      variables: {
        id
      }
    })

    return response.data.adminDestroy
    // if (response.data.adminDestroy && response.data.adminDestroy.status) {
    //   return response.data.adminDestroy.result
    // } else {
    //   console.error(response.data.adminDestroy.error)
    //   throw response.data.adminDestroy.error
    // }
  }

  // static async remove(emails, roles, all = false) {
  //   const response = await graphqlAdmin.mutate({
  //     mutation: gql`
  //       mutation USER_REMOVE(
  //         $emails: [String!]!
  //         $roles: [String!]!
  //         $all: Boolean
  //       ) {
  //         iamRemove(
  //           emails: $emails
  //           roles: $roles
  //           all: $all
  //         )
  //       }
  //     `,

  //     variables: {
  //       emails,
  //       roles,
  //       all
  //     }
  //   })

  //   return response.data.iamRemove
  // }

  //#endregion

  //#region [ Query APIs ]
  static async findAdmin(id) {
    const response = await graphqlAdmin.query({
      query: gql`
        query ADMIN_FIND($id: String!) {
          adminFind(id: $id) {
            id
            authenticationUid
            fullName
            firstName
            lastName
            phoneNumber
            countryCode
            email
            lang
            disabled
            accountType
            avatar {
              name
              publicUrl
            }
            roles
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.adminFind
  }

  static async findCustomer(id) {
    const response = await graphqlCustomer.query({
      query: gql`
        query CUSTOMER_FIND($id: String!) {
          customerFind(id: $id) {
            id
            authenticationUid
            fullName
            firstName
            lastName
            email
            phoneNumber
            avatar {
              name
              publicUrl
            }
            birthDate
            disabled
            deviceTokens
            lang

            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.customerFind
  }

  static async findPortal(id) {
    const response = await graphqlAdmin.query({
      query: gql`
        query ADMIN_FIND($id: String!) {
          adminFind(id: $id) {
            id
            authenticationUid
            fullName
            firstName
            lastName
            phoneNumber
            countryCode
            email
            lang
            disabled
            accountType
            avatar {
              name
              publicUrl
            }
            roles
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.adminFind
  }
  static async listRoles() {
    const response = await graphqlAdmin.query({
      query: gql`
        query {
          adminListRoles
        }
      `
    })

    return response.data.adminListRoles
  }

  static async destroyAdmins(id) {
    const response = await graphqlAdmin.mutate({
      mutation: gql`
        mutation ADMIN_DESTROY($id: String!) {
          adminDestroy(id: $id)
        }
      `,

      variables: {
        id
      }
    })
    return response.data.adminDestroy
  }

  static async destroyPortalAdmins(id) {
    const response = await graphqlPortal.mutate({
      mutation: gql`
        mutation DESTROY($id: String!) {
          protalUserDestroy(id: $id)
        }
      `,

      variables: {
        id
      }
    })
    return response.data.protalUserDestroy
  }

  static async destroyCustomers(id) {
    const response = await graphqlCustomer.mutate({
      mutation: gql`
        mutation DESTROY($id: String!) {
          customerDestroy(id: $id)
        }
      `,

      variables: {
        id
      }
    })
    return response.data.customerDestroy
  }

  /**
   * Retrieve admins with pagination
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith'} filter.operator
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns
   */
  static async fetchAdmins(filter, pagination, orderBy = 'createdAt') {
    const response = await graphqlAdmin.query({
      query: gql`
        query ADMIN_LIST(
          $filter: [FilterInput!]
          $orderBy: String
          $pagination: PaginationInput
        ) {
          adminList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              authenticationUid
              fullName
              firstName
              lastName
              phoneNumber
              countryCode
              email
              lang
              disabled
              accountType
              avatar {
                name
                publicUrl
              }
              roles
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
      `,

      variables: {
        orderBy,
        filter,
        pagination
      }
    })

    return response.data.adminList
  }
  static async fetchPortals(filter, pagination, orderBy = 'createdAt') {
    const response = await graphqlPortal.query({
      query: gql`
        query LIST(
          $filter: [FilterInput!]
          $orderBy: String
          $pagination: PaginationInput
        ) {
          portalUserList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              authenticationUid
              fullName
              firstName
              lastName
              phoneNumber
              email
              emailVerified
              disabled
              accountType
              lang
              roles
              avatar {
                name
                publicUrl
              }
              isRemoved
              branchId
              branch_password
              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,
      variables: {
        orderBy,
        filter,
        pagination
      }
    })

    return response.data.portalUserList
  }

  static async fetchCustomers(filter, pagination, orderBy = 'createdAt') {
    const response = await graphqlCustomer.query({
      query: gql`
        query CUSTOMER_LIST(
          $filter: [FilterInput!]
          $orderBy: String
          $pagination: PaginationInput
        ) {
          customerList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              authenticationUid
              fullName
              firstName
              lastName
              email
              phoneNumber
              avatar {
                name
                publicUrl
              }
              birthDate
              disabled
              deviceTokens
              lang
              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        orderBy,
        filter,
        pagination
      }
    })

    return response.data.customerList
  }

  /**
   * Retrieve users with pagination without admins
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith'} filter.operator
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns
   */
  static async fetchUsers(filter, pagination) {
    const response = await graphqlAdmin.query({
      query: gql`
        query USER_LIST_USERS(
          $filter: [Filter]!
          $pagination: PaginationInput!
        ) {
          listUsers(filter: $filter, pagination: $pagination) {
            id
            userName
            phoneNumber
            email
            avatar
            listedProductsCount
            authenticationUid
            deviceTokens
            lang
            role
            status
            pagesAccess
            notification
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,
      //bankAccounts {
      //   id
      //   bankName
      //   iban
      //   fullName
      //   isDefault
      // }
      // addresses {
      //   id
      //   name
      //   description
      //   lat
      //   long
      //   phoneNumber
      //   isDefault
      // }
      variables: {
        filter,
        pagination
      }
    })

    return response.data.listUsers
  }

  /**
   * Find list of user addresses
   * - listUserAddresses(userId: String!): [Address]!
   * @param {String} id - User ID
   * @returns {Promise<Address[]>}
   */
  static async listUserAddresses(id) {
    const response = await graphqlAdmin.query({
      query: gql`
        query USER_LIST_ADDRESSES($userId: String!) {
          listUserAddresses(userId: $userId) {
            id
            name
            description
            lat
            long
            phoneNumber
            isDefault
            city {
              id
              en
              ar
            }
          }
        }
      `,

      variables: {
        userId: id
      }
    })

    return response.data.listUserAddresses
  }
  //#endregion
}
