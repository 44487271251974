import { createStore } from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import main from './main'
import sideMenu from './side-menu'
import layout from '@/store/layout-store'
import auth from '@/auth/auth-store'
import user from '@/store/user/user-store'
import category from '@/store/category/category-store'
import product from '@/store/product/product-store'
import orders from '@/store/orders/orders-store'
import wallet from '@/store/wallet/wallet-store'
import setting from '@/store/setting/setting-store'
import slider from '@/store/slider/slider-store'
import sidebar from '@/store/sidebar/sidebar-store'
import realtime from '@/store/realtime-store'
import auction from '@/store/auction/auction-store'
import notification from '@/store/notification/notification-store'
import privacy from '@/store/setting/setting-policy-store'
import terms from '@/store/setting/setting-terms-store'
import verify from './verify/verify-store'

const store = createStore({
  modules: {
    main,
    sideMenu,
    layout,
    auth,
    user,
    category,
    product,
    orders,
    wallet,
    setting,
    slider,
    sidebar,
    realtime,
    auction,
    notification,
    privacy,
    terms,
    verify
  },
  mutations: {
    ...vuexfireMutations
  }
})

export function useStore() {
  return store
}

export default store
