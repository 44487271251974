import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  iamCreate: {
    id: 'iamCreate',
    allowedRoles: [roles.owner, roles.editor]
  },
  iamEdit: {
    id: 'iamEdit',
    allowedRoles: [roles.owner, roles.editor]
  },
  iamImport: {
    id: 'iamImport',
    allowedRoles: [roles.owner, roles.editor]
  },
  iamRead: {
    id: 'iamRead',
    allowedRoles: [roles.owner, roles.editor, roles.viewer]
  },
  iamUserAutocomplete: {
    id: 'iamUserAutocomplete',
    allowedRoles: [roles.owner, roles.editor, roles.viewer]
  }
}

export default Permissions
