
<template>
  <div class="flex flex-col gap-1.5">
    <label :for="id" class="mx-1.5 text-[0.9rem]">{{
      $props.title[isRTL ? 'ar' : 'en']
    }}</label>
    <div class="flex flex-col sm:flex-row gap-3 md:gap-5 mb-5 sm:mb-2">
      <div
        class="text-sm rounded-2xl bg-white flex shadow-md border overflow-hidden max-w-[180px]"
      >
        <span
          class="bg-gray-100 px-2.5 py-1.5 flex items-center justify-center w-8"
        >
          {{ currentType === 'fixed' ? '£E' : '%' }}
        </span>
        <input
          type="number"
          :name="id"
          :value="currentValue > 0 ? currentValue : 0"
          class="remove-number-arrows focus-visible:outline-none flex-1 px-2"
          @input="handleChange"
          :min="0"
        />
      </div>
      <div
        class="flex rounded-2xl shadow-md border overflow-hidden bg-white text-[11px]"
      >
        <button
          class="font-semibold whitespace-nowrap w-1/2 py-2 px-3 ltr:pl-3 rtl:pr-3"
          :class="currentType === 'fixed' ? 'bg-gray-100' : 'text-black'"
          @click="handleType('fixed')"
          :disabled="currentType === 'fixed'"
        >
          {{ i18n('discount-input.fixed') }}
        </button>
        <button
          class="font-semibold whitespace-nowrap w-1/2 py-2 px-3 ltr:pr-3 rtl:pl-3"
          :class="currentType === 'percentage' ? 'bg-gray-100' : 'text-black'"
          @click="handleType('percentage')"
          :disabled="currentType === 'percentage'"
        >
          {{ i18n('discount-input.percentage') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      id: new Date(),
      currentValue: this.$props.initValue || '0',
      currentType: this.$props.type,
      money: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false /* doesn't work with directive */
      }
    }
  },
  props: {
    title: {
      type: Object,
      required: true
    },
    initValue: {
      type: Number,
      required: false,
      default: '0'
    },
    type: {
      type: String,
      required: false,
      validator: function (value) {
        return ['fixed', 'percentage'].includes(value)
      },
      default: 'fixed'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL'
    })
  },
  emits: ['change'],
  methods: {
    handleEmit() {
      const currentValue = this.currentValue
      const currentType = this.currentType
      this.$emit('change', {
        value: currentValue,
        type: currentType
      })
    },
    handleType(val) {
      this.currentType = val
      this.handleEmit()
    },
    handleChange() {
      if (this.currentValue < 0) {
        this.currentType = 0
      }
      this.currentValue = 0
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style>
.remove-number-arrows::-webkit-inner-spin-button,
.remove-number-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-number-arrows {
  -moz-appearance: textfield;
}
</style>
