<template>
  <Disclosure v-slot="{ open }">
    <div
      class="bg-white rounded-md transition-all duration-500 shadow-md flex-1 intro-y"
      :style="open ? { maxHeight: 'unset' } : { maxHeight: '40px' }"
    >
      <DisclosureButton
        class="flex min-w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
      >
        <span class="text-base">{{
          props.title[store.getters['layout/isRTL'] ? 'ar' : 'en']
        }}</span>
        <div class="flex justify-center gap-3">
          <slot name="actions" />
          <ChevronUpIcon
            :class="[open ? 'rotate-180 transform' : '']"
            class="text-xl"
          />
        </div>
      </DisclosureButton>
      <DisclosurePanel class="px-4 pt-4 pb-2 text-sm text-gray-500">
        <slot />
      </DisclosurePanel>
    </div>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { defineProps } from 'vue'
import { useStore } from 'vuex'

const props = defineProps(['title'])

const store = useStore()
</script>
