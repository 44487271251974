import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  portalUserCreate: {
    id: 'portalUserCreate',
    allowedRoles: [roles.owner, roles.admin, roles.portalAdmin]
  },
  portalUserEdit: {
    id: 'portalUserEdit',
    allowedRoles: [roles.owner, roles.admin, roles.portalAdmin]
  },
  portalUserDestroy: {
    id: 'portalUserDestroy',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.portalAdmin,
      roles.portalBranchManager
    ]
  },
  portalUserRead: {
    id: 'portalUserRead',
    allowedRoles: [
      roles.owner,
      roles.portalAdmin,
      roles.portalFinance,

      roles.admin,
      roles.viewer,
      roles.editor,
      roles.usersEditor,
      roles.usersViewer
    ]
  },
  portalUserAutocomplete: {
    id: 'portalUserAutocomplete',
    allowedRoles: [roles.owner, roles.portalAdmin, roles.portalFinance]
  },

  portalEmployeeCreate: {
    id: 'portalEmployeeCreate',
    allowedRoles: [roles.portalBranchManager],
    allowedStorageFolders: ['employee']
  },
  portalEmployeeDestroy: {
    id: 'portalEmployeeDestroy',
    allowedRoles: [roles.portalBranchManager]
  },
  portalEmployeeRead: {
    id: 'portalEmployeeRead',
    allowedRoles: [roles.portalBranchManager]
  },
  portalUserChangeStatus: {
    id: 'portalUserChangeStatus',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.portalAdmin,
      roles.portalBranchManager
    ]
  }
}

export default Permissions
