/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'

//#region [ Main Pages ]
import SideMenu from '@/layouts/side-menu/Main.vue'
const ErrorPage = () => import('@/views/error-page/Main.vue')
const Error403Page = () => import('@/views/error-page/error-403-page.vue')
const Error404Page = () => import('@/views/error-page/error-404-page.vue')
const Error500Page = () => import('@/views/error-page/error-500-page.vue')
//#endregion

const Login = () => import('@/views/auth/signin-page.vue')
const ForgotPassword = () => import('@/views/auth/forgot-password.vue')
const Profile = () => import('@/views/profile/profile.vue')
const ChangePassword = () => import('@/views/profile/change-password.vue')

// const HomePage = () => import('@/views/home/home-page.vue')

const AdminListPage = () => import('@/views/admin/admin-list-page.vue')
const AdminFormPage = () => import('@/views/admin/admin-form-page.vue')
const AdminViewPage = () => import('@/views/admin/admin-view-page.vue')
const AdminAccessPage = () => import('@/views/admin/admin-access-page.vue')

const PortalAdminListPage = () =>
  import('@/views/portal-admin/portal-list-page.vue')

const PortalAdminFormPage = () =>
  import('@/views/portal-admin/portal-form-page.vue')

const UsersListPage = () => import('@/views/customer/customer-list-page.vue')
const UserViewPage = () => import('@/views/customer/customer-view-page.vue')

const CategoryListPage = () => import('@/views/category/category-list-page.vue')

const ProductListPage = () => import('@/views/product/product-list-page.vue')

const WalletViewPage = () => import('@/views/wallet/wallet-view-page.vue')

const OrdersListPage = () => import('@/views/orders/orders-list-page.vue')
const OrdersViewPage = () => import('@/views/orders/orders-view-page.vue')

// const MembershipViewPage = () =>
//   import('@/views/settings/settings-tab-memberships.vue')

const SidebarListPage = () => import('@/views/sidebar/sidebar-list-page.vue')
const SidebarFormPage = () => import('@/views/sidebar/sidebar-form-page.vue')

const SliderListPage = () => import('@/views/slider/slider-list-page.vue')
const SliderViewPage = () => import('@/views/slider/slider-view-page.vue')
const SliderFormPage = () => import('@/views/slider/slider-form-page.vue')

const AuctionListPage = () => import('@/views/auction/auction-list-page.vue')
const AuctionViewPage = () => import('@/views/auction/auction-view-page.vue')
const AuctionFormPage = () => import('@/views/auction/auction-form-page.vue')

const Settings = () => import('@/views/settings/settings-list-page.vue')

const routes = [
  {
    path: '',
    component: SideMenu,
    meta: { auth: true },
    children: [
      {
        name: 'profile',
        path: 'profile',
        component: Profile,
        meta: {
          auth: true
        }
      },
      {
        name: 'changePassword',
        path: 'change-password',
        component: ChangePassword,
        meta: {
          auth: true
        }
      },
      {
        name: 'admins',
        path: '',
        alias: '/admins',
        component: AdminListPage,
        meta: {
          auth: true,
          permission: Permissions.userRead
        }
      },
      {
        name: 'adminNew',
        path: 'admins/new',
        component: AdminFormPage,
        meta: {
          auth: true,
          permission: Permissions.userCreate
        }
      },
      {
        name: 'adminEdit',
        path: 'admins/:id/edit',
        props: true,
        component: AdminFormPage,
        meta: {
          auth: true,
          permission: Permissions.userEdit
        }
      },
      {
        name: 'adminView',
        path: 'admins/:id',
        component: AdminViewPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.userRead
        }
      },
      // {
      //   name: 'adminAccessPages',
      //   path: 'admins/:id/access-pages',
      //   component: AdminAccessPage,
      //   props: true,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.pagesAccessRead
      //   }
      // },
      {
        name: 'portal-admin',
        path: 'portal-admin',
        component: PortalAdminListPage,
        meta: {
          auth: true,
          permission: Permissions.portalUserRead
        }
      },
      {
        name: 'portal-adminCreate',
        path: 'portal-admin/new',
        component: PortalAdminFormPage,
        meta: {
          auth: true,
          permission: Permissions.portalUserCreate
        }
      },
      {
        name: 'portal-adminEdit',
        path: 'portal-admin/:id/edit',
        props: true,
        component: PortalAdminFormPage,
        meta: {
          auth: true,
          permission: Permissions.portalUserEdit
        }
      },
      {
        name: 'users',
        path: 'users',
        component: UsersListPage,
        meta: {
          auth: true,
          permission: Permissions.userRead
        }
      },
      {
        name: 'userView',
        path: 'users/:id',
        props: true,
        component: UserViewPage,
        meta: {
          auth: true,
          permission: Permissions.userRead
        }
      },
      {
        name: 'category',
        path: 'category',
        component: CategoryListPage,
        meta: {
          auth: true,
          permission: Permissions.categoryRead
        }
      },
      {
        name: 'products',
        path: 'products',
        component: ProductListPage,
        meta: {
          auth: true,
          permission: Permissions.productRead
        }
      },
      {
        name: 'wallet',
        path: 'wallet',
        component: WalletViewPage,
        meta: {
          auth: true,
          permission: Permissions.walletRead
        }
      },
      {
        name: 'orders',
        path: 'orders',
        component: OrdersListPage,
        meta: {
          auth: true,
          permission: Permissions.orderRead
        }
      },
      {
        name: 'ordersview',
        path: 'orders/:id',
        component: OrdersViewPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.orderRead
        }
      },
      // {
      //   name: 'memberships',
      //   path: 'memberships',
      //   component: MembershipViewPage,
      //   meta: {
      //     auth: true
      //     // permission: Permissions.values.promoCodeRead
      //   }
      // },
      {
        name: 'auction',
        path: 'auction',
        component: AuctionListPage,
        meta: {
          auth: true,
          permission: Permissions.auctionRead
        }
      },
      {
        name: 'auctionNew',
        path: 'auction/new',
        component: AuctionFormPage,
        meta: {
          auth: true,
          permission: Permissions.auctionCreate
        }
      },
      {
        name: 'auctionUpdate',
        path: 'auction/:id/edit',
        component: AuctionFormPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.auctionEdit
        }
      },
      {
        name: 'auctionView',
        path: 'auction/:id',
        component: AuctionViewPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.auctionRead
        }
      },
      {
        name: 'sidebar',
        path: 'sidebar',
        component: SidebarListPage,
        meta: {
          auth: true,
          permission: Permissions.sidebarRead
        }
      },
      {
        name: 'sidebarNew',
        path: 'sidebar/new',
        component: SidebarFormPage,
        meta: {
          auth: true,
          permission: Permissions.sidebarCreate
        }
      },
      {
        name: 'sidebarUpdate',
        path: 'sidebar/edit/:id',
        component: SidebarFormPage,
        props: true,
        meta: {
          auth: true,
          permission: Permissions.sidebarEdit
        }
      },
      {
        name: 'slider',
        path: 'slider',
        component: SliderListPage,
        meta: {
          auth: true,
          permission: Permissions.sliderRead
        }
      },
      {
        name: 'sliderNew',
        path: 'slider/new',
        component: SliderFormPage,
        meta: {
          auth: true,
          permission: Permissions.sliderCreate
        }
      },
      {
        name: 'sliderEdit',
        path: 'slider/edit/:id',
        props: true,
        component: SliderFormPage,
        meta: {
          auth: true,
          permission: Permissions.sliderEdit
        }
      },
      {
        name: 'sliderView',
        path: 'slider/:id',
        props: true,
        component: SliderViewPage,
        meta: {
          auth: true,
          permission: Permissions.sliderRead
        }
      },
      {
        name: 'settings',
        path: 'settings',
        component: Settings,
        meta: {
          auth: true,
          permission: [Permissions.settingsRead, Permissions.settingsEdit]
        }
      }
    ]
  },
  {
    name: 'login',
    path: '/auth/login',
    component: Login,
    meta: { unauth: true }
  },
  {
    name: 'forgotPassword',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    meta: { unauth: true }
  },
  {
    name: 'errorPage',
    path: '/error-page',
    component: ErrorPage
  },
  {
    name: 'error403',
    path: '/403',
    component: Error403Page
  },
  {
    name: 'error404',
    path: '/404',
    component: Error404Page
  },
  {
    name: 'error500',
    path: '/500',
    component: Error500Page
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior(_, _2, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

// eslint-disable-next-line
import authGuard from '@/router/auth-guard'
import Permissions from '@/security/permissions'
router.beforeResolve(async (to, from, next) => {
  await authGuard.beforeRouteEnter(to, from, next)
})

// export function getPages(pages = routes) {
//   const routesPages = []
//   for (let i = 0; i < pages.length; i++) {
//     const route = pages[i]
//     if (route.name) {
//       routesPages.push({ name: route.name, checked: false })
//     }

//     if (route.children && route.children.length) {
//       const result = getPages(route.children, 0)
//       routesPages.push(...result)
//     }
//   }
//   return routesPages
// }
export default router
