class PermissionChecker {
  constructor(currentUser) {
    this.currentUser = currentUser
    this.userRoles = currentUser?.roles || null
  }

  match(permission) {
    if (!permission) return true
    if (Array.isArray(permission)) {
      if (!permission.length) {
        return false
      }
      return permission.some(per => this.rolesMatchOneOf(per.allowedRoles))
    }
    return this.rolesMatchOneOf(permission.allowedRoles)
  }

  rolesMatchOneOf(arg) {
    if (!this.userRoles || !Array.isArray(arg) || !arg.length) return false
    return arg.some(role => this.userRoles.includes(role))
  }

  get isEmptyPermission() {
    if (!this.isAuthenticated) return false

    return !this.userRoles || this.userRoles.length
  }

  get isAuthenticated() {
    return !!this.currentUser && !!this.currentUser.id
  }

  get isEmailVerified() {
    if (!this.isAuthenticated) return false
    return this.currentUser.emailVerified
  }
}

export default PermissionChecker
