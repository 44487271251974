/* eslint-disable */ 
import notificationListStore from '@/store/notification/notification-list-store';
import notificationFormStore from '@/store/notification/notification-form-store';
import notificationViewStore from '@/store/notification/notification-view-store';
import userNotificationListStore from '@/store/notification/user-notification-list-store';

export default {
  namespaced: true,

  modules: {
    list: notificationListStore,
    user: userNotificationListStore,
    form: notificationFormStore,
    view: notificationViewStore
  },
};
