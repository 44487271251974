<template>
  <label
    :for="id + '_button'"
    :class="{ active: isActive }"
    class="toggle__button"
  >
    <!-- <span v-if="isActive" class="toggle__label">{{ enableText }}</span>
    <span v-if="!isActive" class="toggle__label">{{ disabledText }}</span> -->

    <input
      type="checkbox"
      :disabled="disabled"
      :id="id + '_button'"
      v-model="checkedValue"
    />
    <span class="toggle__switch" :disabled="disabled">
      <span class="flex items-center fit">
        <span class="flex justify-center toggle__switch-label radius-20" :style="checkedValue ? '' : 'color: #FFFFFF;'">{{ $t('iam.disable') }}</span>
        <span class="flex justify-center toggle__switch-label radius-20" :style="checkedValue ? 'color: #FFFFFF;' : ''">{{ $t('iam.enable') }}</span>
      </span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'app-toggle-button',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    labelEnableText: {
      type: String,
      default: 'Enable'
    },
    labelDisableText: {
      type: String,
      default: 'Disable'
    },
    id: {
      type: String,
      default: 'primary'
    },
    defaultState: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentState: this.defaultState
    }
  },
  watch: {
    defaultState: function defaultState() {
      this.currentState = Boolean(this.defaultState)
    }
  },
  computed: {
    // currentState() {
    //     return this.defaultState
    // },
    isActive() {
      return this.currentState
    },
    enableText() {
      return this.labelEnableText
    },
    disabledText() {
      return this.labelDisableText
    },
    checkedValue: {
      get() {
        return this.currentState
      },
      set(newValue) {
        this.currentState = newValue
        this.$emit('change', newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle__button {
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}
.toggle__button input[type='checkbox'] {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
}
.toggle__button .toggle__switch {
  display: inline-block;
  border-radius: 6px;
  height: 45px;
  width: 220px;
  background: #FFFFFF;
  box-shadow: inset 0 0 1px #bfcbd9;
  position: relative;
  // margin-left: 10px;
  transition: all 0.35s;
}

.toggle__switch-label {
  z-index: 1;
  width: 50%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #247ba0;
  transition: all 0.75s;
}

.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
  content: '';
  position: absolute;
  display: block;
  height: 45px;
  width: 110px;
  border-radius: 6px;
  left: 0;
  top: 0;
  transform: translateX(0);
  transition: all 0.35s cubic-bezier(0.5, -0.6, 0.5, 1.6);
}
.toggle__button .toggle__switch::after {
  background: #247ba0;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15);
}
.toggle__button .toggle__switch::before {
  background: #247ba0;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15);
  opacity: 0;
}
.active .toggle__switch {
  background: #FFFFFF;
  box-shadow: inset 0 0 1px #bfcbd9;
}
.active .toggle__switch::after,
.active .toggle__switch::before {
  transform: translateX(40px - 18px);
}
.active .toggle__switch::after {
  left: 87px;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.15);
}
@media (max-width: 1439px) {
  .toggle__button input[type='checkbox'] {
    width: 1px;
    height: 1px;
  }
  .toggle__button .toggle__switch {
    border-radius: calc(4px + (6 - 4) * ((100vw - 320px) / (1440 - 320))) !important;
    height: calc(25px + (45 - 25) * ((100vw - 320px) / (1440 - 320))) !important;
    width: calc(110px + (220 - 110) * ((100vw - 320px) / (1440 - 320))) !important;
    margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1440 - 320))) !important;
  }
  .toggle__switch-label {
    font-size: calc(10px + (18 - 10) * ((100vw - 320px) / (1440 - 320))) !important;
    line-height: calc(20px + (24 - 20) * ((100vw - 320px) / (1440 - 320))) !important;
  }
  .toggle__button .toggle__switch::after,
  .toggle__button .toggle__switch::before {
    height: calc(25px + (50 - 25) * ((100vw - 320px) / (1440 - 320))) !important;
    width: calc(55px + (110 - 55) * ((100vw - 320px) / (1440 - 320))) !important;
    border-radius: calc(4px + (6 - 4) * ((100vw - 320px) / (1440 - 320))) !important;
  }
  .active .toggle__switch::after {
    left: calc(32px + (87 - 32) * ((100vw - 320px) / (1440 - 320))) !important;
  }
}

</style>

<style lang="scss" scoped>
// .toggle__button {
//   vertical-align: middle;
//   user-select: none;
//   cursor: pointer;
// }
// .toggle__button input[type='checkbox'] {
//   opacity: 0;
//   position: absolute;
//   width: 1px;
//   height: 1px;
// }
// .toggle__button .toggle__switch {
//   display: inline-block;
//   height: 12px;
//   border-radius: 6px;
//   width: 40px;
//   background: #bfcbd9;
//   box-shadow: inset 0 0 1px #bfcbd9;
//   position: relative;
//   margin-left: 10px;
//   transition: all 0.25s;
// }
// .toggle__button .toggle__switch::after,
// .toggle__button .toggle__switch::before {
//   content: '';
//   position: absolute;
//   display: block;
//   height: 18px;
//   width: 18px;
//   border-radius: 50%;
//   left: 0;
//   top: -3px;
//   transform: translateX(0);
//   transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
// }
// .toggle__button .toggle__switch::after {
//   background: #4d4d4d;
//   box-shadow: 0 0 1px #666;
// }
// .toggle__button .toggle__switch::before {
//   background: #4d4d4d;
//   box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
//   opacity: 0;
// }
// .active .toggle__switch {
//   background: #adedcb;
//   box-shadow: inset 0 0 1px #adedcb;
// }
// .active .toggle__switch::after,
// .active .toggle__switch::before {
//   transform: translateX(40px - 18px);
// }
// .active .toggle__switch::after {
//   left: 23px;
//   background: #53b883;
//   box-shadow: 0 0 1px #53b883;
// }
</style>
