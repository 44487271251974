/* eslint-disable */ import { SettingService } from '@/store/setting/setting-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
// import { routerAsync } from '@/app-module';
import router from '@/router'
// import FirebaseRepository from '@/shared/firebase/firebase-repository'

export default {
  namespaced: true,

  state: {
    loading: false,
    rows: null
  },

  getters: {
    rows: state => state.rows || [],
    loading: state => !!state.loading
  },

  mutations: {
    RESET(state) {
      state.loading = false
      state.rows = null
    },

    FETCH_STARTED(state) {
      state.rows = null
      state.loading = true
    },
    FETCH_SUCCESS(state, payload) {
      state.rows = payload
      state.loading = false
    },
    FETCH_ERROR(state) {
      state.rows = null
      state.loading = false
    },

    UPDATE_STARTED(state) {
      state.loading = true
    },
    UPDATE_SUCCESS(state) {
      state.loading = false
    },
    UPDATE_ERROR(state) {
      state.loading = false
    }
  },

  actions: {
    async doFetch({ commit }) {
      try {
        commit('FETCH_STARTED')
        const response = await SettingService.listMemberships()
        commit('FETCH_SUCCESS', {
          rows: response
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },
    async updateMemberships({ commit, dispatch }, { memberships }) {
      try {
        commit('UPDATE_STARTED')
        await SettingService.updateMemberships(memberships)
        commit('FETCH_SUCCESS')
        Message.success(i18n('settings.appliedSuccessfully'))

        dispatch('doFetch')
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
        dispatch('doFetch')
      }
    }
  }
}
