<template>
  <div>
    <Dropzone
      ref-key="dropzoneRef"
      id="dropzoneRef"
      :options="{
        url: 'https://httpbin.org/post',
        paramName: 'file',
        thumbnailWidth: 150,
        maxFilesize: maxSize,
        // clickable: !isMaxUploaded,
        acceptedFiles: acceptedFiles,
        uploadMultiple: isMultiple,
        addRemoveLinks: true,
        maxFiles: max,
        headers: { 'My-Awesome-Header': 'header value' }
      }"
      class="dropzone cursor-pointer h-36"
    >
      <div class="text-lg font-medium">
        {{ message ? i18n(message) : i18n('common.fileUploaderHint') }}
      </div>
    </Dropzone>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, provide } from 'vue'
import Dropzone from 'dropzone'
import { FileUploader } from '@/shared/uploader/file-uploader'

export default defineComponent({
  props: {
    multiple: Boolean,
    path: String,
    message: String,
    type: String,
    maxSize: {
      type: Number,
      default: 1
    },
    maxFiles: {
      type: Number,
      default: 1
    }
  },
  computed: {
    isMultiple() {
      return this.multiple || false
    },
    acceptedFiles() {
      return this.type || 'image/*'
    },
    max() {
      return this.maxFiles || this.multiple ? null : 1
    }
  },
  setup(props) {
    let uploadLoading = ref(false)
    const dropzoneRef = ref()
    const filesToSave = ref([])
    const indexes = ref([])
    provide('bind[dropzoneRef]', (el) => {
      dropzoneRef.value = el
    })
    const clearDropzone = () => {
      Dropzone.forElement('#dropzoneRef').removeAllFiles(true)
      while (filesToSave.value.length > 0) {
        filesToSave.value.pop()
      }
    }
    const getDropZoneInstance = () => {
      return dropzoneRef.value
    }
    const setupDropZone = () => {
      const elDropzoneRef = getDropZoneInstance()

      if (props.path) {
        elDropzoneRef.dropzone.on('addedfile', async (file) => {
          const uploadedFile = await FileUploader.upload(
            props.path,
            file,
            file.upload.uuid
          )
          filesToSave.value.push(uploadedFile.publicUrl)
          indexes.value.push(file.upload.uuid)
        })
        elDropzoneRef.dropzone.on('removedfile', async (file) => {
          await FileUploader.delete(props.path, file, file.upload.uuid)
          const index = indexes.value.findIndex(
            (item) => item.upload.uuid === file.upload.uuid
          )
          if (index >= 0) {
            filesToSave.value.splice(index, 1)
          }
        })
      } else {
        elDropzoneRef.dropzone.on('success', (file) => {
          if (filesToSave.value.length < props.maxFiles) {
            filesToSave.value.push(file)
          }
        })
        elDropzoneRef.dropzone.on('removedfile', (file) => {
          const index = filesToSave.value.findIndex(
            (item) => item.upload.uuid === file.upload.uuid
          )
          if (index >= 0) {
            filesToSave.value.splice(index, 1)
          }
        })
      }

      elDropzoneRef.dropzone.on('complete', () => {
        uploadLoading = false
      })

      elDropzoneRef.dropzone.on('uploadprogress', () => {
        uploadLoading = true
      })
    }
    onMounted(() => {
      setupDropZone()
    })
    return {
      setupDropZone,
      uploadLoading,
      filesToSave,
      getDropZoneInstance,
      clearDropzone
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
