<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu />
    <!-- <TopBar /> -->
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav relative">
        <!-- BEGIN: Logo -->
        <div>
          <img
            src="/images/shamy-logo1.png"
            alt="Icewall Tailwind HTML Admin Template"
            class="w-24 mx-auto"
          />
          <!-- <span class="hidden xl:block text-white font-bold text-xl ml-3">SHAMY</span> -->
        </div>
        <!-- END: Logo -->
        <div class="h-6"></div>

        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="typeof menu === 'string' && menu === 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            />
            <li
              v-else-if="
                typeof menu === 'object' &&
                  menu.type === 'header' &&
                  hasPermissionToRead(menu.permission)
              "
              :key="menu.title.en + menuKey"
              class="my-6 text-white intro-x"
            >
              <h3 class="text-lg">
                {{ menu.title[isRTL ? 'ar' : 'en'] }}
              </h3>
              <div class="side-nav__devider" />
            </li>
            <li v-else-if="typeof menu === 'object'" :key="menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                v-if="hasPermissionToRead(menu.permission)"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <svg
                    v-if="menu.icon == 'PortalIcon'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      d="M8 11A5 5 0 1 0 8 1a5 5 0 0 0 0 10Zm5.023 2.023C11.772 11.76 10.013 11 8 11c-4 0-7 3-7 7v5h7m2-3.5a2.5 2.5 0 1 0 5.002-.002A2.5 2.5 0 0 0 10 19.5ZM23 15l-3-3l-6 6m3.5-3.5l3 3l-3-3Z"
                    />
                  </svg>
                  <svg
                    v-if="menu.icon == 'AuctionIcon'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M14.005 20.003v2h-12v-2h12ZM14.59.689l7.778 7.778l-1.414 1.414l-1.061-.353l-2.475 2.475l5.657 5.657l-1.414 1.414l-5.657-5.657L13.6 15.82l.283 1.131l-1.415 1.415l-7.778-7.779l1.414-1.414l1.132.283l6.293-6.293l-.353-1.06L14.59.688Zm.707 3.535l-7.071 7.072l3.535 3.535l7.071-7.071l-3.535-3.535Z"
                    />
                  </svg>
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title mr-2">
                  {{ i18n(`menu.${menu.title}`) }}
                  <span
                    class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                    v-if="menu.title == 'Orders' && ordersBadge > 0"
                    >{{ ordersBadge }}</span
                  >
                  <span
                    class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                    v-if="menu.title == 'Products' && productsBadge > 0"
                    >{{ productsBadge }}</span
                  >
                  <span
                    class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                    v-if="menu.title == 'Payments' && requestsBadge > 0"
                    >{{ requestsBadge }}</span
                  >
                  <span
                    class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5"
                    v-if="menu.title == 'Complaints' && newComplaintsCount > 0"
                    >{{ newComplaintsCount }}</span
                  >
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <ActivityIcon />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
        <div class="!mb-2 flex justify-center pt-5 runProf">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/home-food-plus.appspot.com/o/admin%2Fpublic%2Frunprof.svg?alt=media&token=92e71269-efea-4a44-b024-80f6b5c5730a"
            alt=""
          />
        </div>
      </nav>
      <!-- END: Side Menu -->

      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import { mapActions, mapGetters } from 'vuex'
import PermissionChecker from '@/security/permission-checker'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .removeClass('login-rtl')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      formattedMenu,
      darkMode,
      router,
      linkTo,
      enter,
      leave
    }
  },
  computed: {
    ...mapGetters({
      ordersBadge: 'realtime/ordersBadge',
      productsBadge: 'realtime/productsBadge',
      requestsBadge: 'realtime/requestsBadge',
      listenerLoading: 'realtime/loading',
      currentUser: 'auth/currentUser',
      isRTL: 'layout/isRTL'
    })
  },
  async created() {
    this.doRunListenerOnNewOrders()
    this.doRunListenerOnNewProducts()
    this.doRunListenerOnNewRequests()
  },
  methods: {
    ...mapActions({
      doRunListenerOnNewOrders: 'realtime/doRunListenerOnNewOrders',
      doRunListenerOnNewProducts: 'realtime/doRunListenerOnNewProducts',
      doRunListenerOnNewRequests: 'realtime/doRunListenerOnNewRequests'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    hasPermissionToRead(permission) {
      return new PermissionChecker(this.currentUser).match(permission)
    }
  }
})
</script>

<style lang="scss">
.runProf {
  position: absolute;
  bottom: 0%;
  left: 10%;
  right: unset;
  @media only screen and (max-width: 1280px) {
    width: 94%;
    left: 0%;
    right: 0%;
    // bottom: unset;
  }
}

[dir='rtl'] .runProf {
  position: absolute;
  bottom: 0%;
  right: 25%;
  left: unset;
  @media only screen and (max-width: 1280px) {
    right: 1% !important;
    // display: none;
  }
  @media only screen and (max-width: 768px) {
    left: 87% !important;
  }
}

[dir='rtl'] .side-nav > ul > li > .side-menu.side-menu--active:before {
  content: '';
  transform: rotate(180deg) scale(1.04) !important;
  right: unset !important;
  left: 0 !important;
}

[dir='rtl'] .side-nav > ul > li > .side-menu.side-menu--active:after {
  content: '';
  transform: rotate(270deg) scale(1.04) !important;
  right: unset !important;
  left: 0 !important;
}

[dir='rtl']
  .side-nav
  > ul
  > li
  > .side-menu.side-menu--active
  .side-menu__icon:before {
  content: '';
  right: unset !important;
  left: 0 !important;
}

[dir='rtl'] .side-nav .side-menu {
  padding-right: 1.25rem !important;
}
</style>
