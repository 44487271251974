import Permissions from '@/security/permissions'

const state = () => {
  return {
    menu: [
      {
        type: 'header',
        title: { en: 'Users', ar: 'المستخدمين' },
        permission: [Permissions.userRead, Permissions.portalUserRead]
      },
      {
        icon: 'UserCheckIcon',
        pageName: 'admins',
        title: 'Admins',
        permission: Permissions.userRead
      },
      // {
      //   icon: 'PortalIcon',
      //   pageName: 'portal-admin',
      //   title: 'PortalAdmin'
      //   // permission: Permissions.values.adminRead
      // },
      {
        icon: 'UsersIcon',
        pageName: 'users',
        title: 'Users',
        permission: Permissions.userRead
      },
      {
        icon: 'CopyIcon',
        pageName: 'portal-admin',
        title: 'Portals',
        permission: Permissions.portalUserRead
      },
      {
        type: 'header',
        title: { en: 'Providers', ar: 'المزودون' },
        permission: [
          Permissions.categoryRead,
          Permissions.productRead,
          Permissions.walletRead,
          Permissions.orderRead
        ]
      },
      {
        // icon: 'MenuIcon',
        icon: 'ListIcon',
        pageName: 'category',
        title: 'Categories',
        permission: Permissions.categoryRead
      },
      {
        icon: 'DatabaseIcon',
        pageName: 'products',
        title: 'Products',
        permission: Permissions.productRead
      },
      {
        icon: 'DollarSignIcon',
        pageName: 'wallet',
        title: 'Payments',
        permission: Permissions.walletRead
      },
      {
        icon: 'GiftIcon',
        pageName: 'orders',
        title: 'Orders',
        permission: Permissions.orderRead
      },
      {
        type: 'header',
        title: { en: 'Mobile', ar: 'الموبايل' },
        permission: [
          Permissions.auctionRead,
          Permissions.sidebarRead,
          Permissions.sliderRead
        ]
      },
      {
        icon: 'ShoppingBagIcon',
        pageName: 'auction',
        title: 'Auction',
        permission: Permissions.auctionRead
      },
      {
        icon: 'MenuIcon',
        pageName: 'sidebar',
        title: 'Sidebar',
        permission: Permissions.sidebarRead
      },
      {
        icon: 'LayersIcon',
        pageName: 'slider',
        title: 'Slider',
        permission: Permissions.sliderRead
      },
      // {
      //   // icon: 'BarChartIcon',
      //   icon: 'BarChart2Icon',
      //   // icon: 'FileTextIcon',
      //   pageName: 'reports',
      //   title: 'Reports',
      //   permission: Permissions.values.reportRead
      // },
      // {
      //   icon: 'MessageSquareIcon',
      //   pageName: 'complaint',
      //   title: 'Complaints',
      //   permission: Permissions.values.complaintRead
      // },
      {
        type: 'header',
        title: { en: 'Settings', ar: 'الاعدادات' },
        permission: Permissions.settingsRead
      },
      {
        icon: 'SettingsIcon',
        pageName: 'settings',
        title: 'Settings',
        permission: Permissions.settingsRead
      },
      'devider'
    ]
  }
}

const getters = {
  menu: state => state.menu
}
const mutations = {}
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
