/* eslint-disable */

import { UserService } from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
// import FirebaseRepository from '@/shared/firebase/firebase-repository'
// import PermissionChecker from '@/security/permission-checker'
// import Permissions from '@/security/permissions'

export default {
  namespaced: true,
  state: {
    loading: false,
    findLoading: false,
    saveLoading: false,
    record: null,
    roles: []
  },
  getters: {
    record: state => state.record,
    loading: state => state.loading,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading,
    roles: state => state.roles
  },
  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },
    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },
    ROLES_STARTED(state) {
      state.loading = true
    },
    ROLES_SUCCESS(state, roles) {
      state.loading = false
      state.roles = roles
    },
    ROLES_ERROR(state) {
      state.loading = false
    },
    ADD_STARTED(state) {
      state.saveLoading = true
    },
    ADD_SUCCESS(state) {
      state.saveLoading = false
    },
    ADD_ERROR(state) {
      state.saveLoading = false
    },
    UPDATE_STARTED(state) {
      state.saveLoading = true
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, { id, type }) {
      try {
        commit('FIND_STARTED')
        const record =
          type === 'admin'
            ? await UserService.findAdmin(id)
            : type === 'portalAdmin'
            ? await UserService.findPortal(id)
            : await UserService.findCustomer(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        type === 'admin'
          ? router.push(`/admins`)
          : type === 'portalAdmin'
          ? router.push(`/portal-admin`)
          : router.push(`/users`)
      }
    },

    async doAdd({ commit }, { type, values }) {
      try {
        commit('ADD_STARTED')
        if (type === 'admin') {
          await UserService.create(values)
        } else {
          const { firstName, lastName, phoneNumber, email } = values
          await UserService.createPortal({
            firstName,
            lastName,
            phoneNumber,
            email
          })
        }
        commit('ADD_SUCCESS')

        if (type === 'admin') {
          Message.success(i18n('admin.create.success'))
          router.push(`/`)
        } else {
          Message.success(i18n('portalAdmin.create.success'))
          router.push('/portal-admin')
        }
        // }
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },

    async listRoles({ commit }) {
      try {
        commit('ROLES_STARTED')
        const roles = await UserService.listRoles()
        commit('ROLES_SUCCESS', roles)
      } catch (error) {
        Errors.handle(error)
        commit('ROLES_ERROR')
      }
    },

    async doUpdate({ commit, rootGetters, dispatch }, { type, ...values }) {
      try {
        commit('UPDATE_STARTED')
        const { id, ...data } = values

        type === 'admin'
          ? await UserService.edit(id, data)
          : await UserService.editPortal(values)

        commit('UPDATE_SUCCESS')
        if (type === 'admin') {
          const currentUser = rootGetters['auth/currentUser']
          if (currentUser.id === values.id) {
            dispatch('auth/doRefreshCurrentUser')
          }
          Message.success(i18n('admin.update.success'))
          router.push('/admins')
        } else {
          Message.success(i18n('portalAdmin.update.success'))
          router.push('/portal-admin')
        }
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    },

    async doSetAdminAccess(
      { commit, rootGetters, dispatch },
      { id, pagesAccess }
    ) {
      try {
        commit('UPDATE_STARTED')
        await UserService.setAdminAccess(id, pagesAccess)
        commit('UPDATE_SUCCESS')
        const currentUser = rootGetters['auth/currentUser']
        if (currentUser.id === id) {
          dispatch('auth/doRefreshCurrentUser')
        }
        Message.success(i18n('admin.update.success'))
        router.push('/admins')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
