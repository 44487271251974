/* eslint-disable */
import { SidebarService } from '@/store/sidebar/sidebar-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
import { collection, doc, getFirestore } from 'firebase/firestore'
import { FileUploader } from '@/shared/uploader/file-uploader'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.record = null
    },

    FIND_STARTED(state) {
      state.record = null
      state.findLoading = true
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.findLoading = false
    },

    FIND_ERROR(state) {
      state.record = null
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET')
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await SidebarService.find(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/sidebar')
      }
    },

    async doCreate({ commit }, values) {
      try {
        if (values.image) {
          const id = doc(collection(getFirestore(), 'ids')).id
          const path = `sidebar/${id}`
          const img = await FileUploader.upload(
            path,
            values.image,
            `slidebar-image-${Date.now()}`
          )

          commit('ADD_STARTED')
          await SidebarService.create({
            isActive: values.isActive,
            itemId: values.itemId,
            title: values.title,
            type: values.type,
            imageIcon: img
          })
        } else {
          await SidebarService.create({
            isActive: values.isActive,
            itemId: values.itemId,
            title: values.title,
            type: values.type,
            imageIcon: { publicUrl: null, name: null }
          })
        }

        commit('ADD_SUCCESS')
        Message.success(i18n('sidebar.create.success'))
        router.push('/sidebar')
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED')
        const { isActive, itemId, title, type, imageIcon } = values
        if (values.image !== undefined) {
          const imageId = doc(collection(getFirestore(), 'ids')).id
          const path = `sidebar/${imageId}`
          const img = await FileUploader.upload(
            path,
            values.image,
            `slidebar-image-${Date.now()}`
          )
          await SidebarService.update(id, {
            isActive,
            itemId,
            title,
            type,
            imageIcon: img
          })
        } else {
          await SidebarService.update(id, {
            isActive,
            itemId,
            title,
            type,
            imageIcon
          })
        }
        commit('UPDATE_SUCCESS')
        Message.success(i18n('sidebar.update.success'))
        router.push('/sidebar')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
