/* eslint-disable */
import sidebarListStore from '@/store/sidebar/sidebar-list-store';
import sidebarFormStore from '@/store/sidebar/sidebar-form-store';

export default {
  namespaced: true,

  modules: {
    list: sidebarListStore,
    form: sidebarFormStore
  },
};
