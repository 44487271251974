/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlProduct)

export class ProductService {
         static async accept(productId, operationType) {
           switch (operationType) {
             case 'add':
               return this._decideProductAdd(productId, true)
             case 'update':
               return this._decideProductUpdate(productId, true)
           }
         }

         static async reject(productId, operationType) {
           switch (operationType) {
             case 'add':
               return this._decideProductAdd(productId, false)
             case 'update':
               return this._decideProductUpdate(productId, false)
           }
         }

         static async _decideProductAdd(productId, acceptAdd) {
           const response = await graphqlClient.mutate({
             mutation: gql`
               mutation PRODUCT_DECIDE_ADD(
                 $productId: String!
                 $acceptAdd: Boolean!
               ) {
                 decideProductAdd(productId: $productId, acceptAdd: $acceptAdd)
               }
             `,

             variables: {
               productId,
               acceptAdd
             }
           })

           // return response.data.decideProductAdd
           if (
             response.data.decideProductAdd &&
             response.data.decideProductAdd.status
           ) {
             return response.data.decideProductAdd.result
           } else {
             console.error(response.data.decideProductAdd.error)
             throw response.data.decideProductAdd.error
           }
         }

         static async _decideProductUpdate(productId, acceptUpdate) {
           const response = await graphqlClient.mutate({
             mutation: gql`
               mutation PRODUCT_DECIDE_ADD(
                 $productId: String!
                 $acceptUpdate: Boolean!
               ) {
                 decideProductUpdate(
                   productId: $productId
                   acceptUpdate: $acceptUpdate
                 )
               }
             `,

             variables: {
               productId,
               acceptUpdate
             }
           })

           // return response.data.decideProductUpdate
           if (
             response.data.decideProductUpdate &&
             response.data.decideProductUpdate.status
           ) {
             return response.data.decideProductUpdate.result
           } else {
             console.error(response.data.decideProductUpdate.error)
             throw response.data.decideProductUpdate.error
           }
         }

         static async destroy(id) {
           const response = await graphqlClient.mutate({
             mutation: gql`
               mutation PRODUCT_DESTROY($productId: String!) {
                 removeProduct(productId: $productId)
               }
             `,

             variables: {
               productId: id
             }
           })

           // return response.data.removeProduct
           if (
             response.data.removeProduct &&
             response.data.removeProduct.status
           ) {
             return response.data.removeProduct.result
           } else {
             console.error(response.data.removeProduct.error)
             throw response.data.removeProduct.error
           }
         }
         //#endregion

         //#region [ Query APIs ]
         static async find(id) {
           const response = await graphqlClient.query({
             query: gql`
               query PRODUCT_FIND($productId: String!) {
                 findProductById(productId: $productId) {
                   id
                   title
                   description
                   originalPrice
                   sellingPrice
                   priceWithComm
                   priceWithVat
                   images
                   category {
                     en
                     ar
                   }
                   subCategory {
                     en
                     ar
                   }
                   brand {
                     en
                     ar
                   }
                   class {
                     en
                     ar
                   }
                   type {
                     en
                     ar
                   }
                   size {
                     en
                     ar
                   }
                   color {
                     en
                     ar
                   }
                   case {
                     en
                     ar
                   }
                   pickUp {
                     lat
                     long
                     address
                   }
                   receipt
                   package
                   sellingType
                   clicks
                   deliveryCompaniesIds
                   country
                   city
                   endTime
                   minimumBidding
                   sellerId
                   sellerName
                   status
                   parentProductId
                   childProductId
                   createdBy
                   createdAt
                   updatedBy
                   updatedAt
                 }
               }
             `,

             variables: {
               productId: id
             }
           })

           return response.data.findProductById
         }

         static async list(filter, pagination, orderBy = 'price') {
           const response = await graphqlClient.query({
             query: gql`
               query PRODUCT_LIST(
                 $filter: [FilterInput!]
                 $orderBy: String
                 $pagination: PaginationInput
               ) {
                 productList(
                   filter: $filter
                   orderBy: $orderBy
                   pagination: $pagination
                 ) {
                   pagination {
                     isFirstPage
                     isLastPage
                   }
                   count
                   rows {
                     id
                     name
                     is_active
                     price
                     currency
                     provider_name
                     provider_product_id
                   }
                 }
               }
             `,

             variables: {
               filter,
               orderBy,
               pagination
             }
           })

           return response.data.productList
         }

         static async refresh(provider_name) {
           const response = await graphqlClient.mutate({
             mutation: gql`
               mutation REFRESH($provider_name: ProviderTypeEnum) {
                 addProductsToDatabase(provider_name: $provider_name)
               }
             `,
             variables: {
               provider_name
             }
           })
           return response.data.addProductsToDatabase
         }
         //#endregion
       }
