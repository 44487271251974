/* eslint-disable */
import service from '@/auth/auth-service'
import crypto from '@/shared/crypto'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
import FirebaseRepository from '@/shared/firebase/firebase-repository'
// import { doc, onSnapshot } from 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    authenticationUser: null,
    currentUser: null,
    currentUserSocial: null,

    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loadingChangeMyPassword: false,

    loading: false,
    loadingGoogle: false,
    loadingFacebook: false,
    loadingTwitter: false
  },

  getters: {
    authenticationUser: state => state.authenticationUser,
    currentUser: state => state.currentUser,
    currentUserSocial: state => state.currentUserSocial,

    currentUserDetails: (state, getters) =>
      getters.currentUser ? getters.currentUser : null,

    currentUserFirstName: (state, getters) =>
      getters.currentUser ? getters.currentUser.fullName.split(' ')[0] : null,

    currentUserLastName: (state, getters) =>
      getters.currentUser ? getters.currentUser.fullName.split(' ')[1] : null,

    currentUserEmail: (state, getters) =>
      getters.currentUser ? getters.currentUser.email : null,

    currentUserFullName: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.fullName || getters.currentUser.userName
        : '',

    role: (state, getters) =>
      getters.currentUser ? getters.currentUser.role || null : null,

    roles: (state, getters) =>
      getters.currentUser ? getters.currentUser.roles || [] : [],

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,
    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: state => !!state.loading,
    loadingGoogle: state => !!state.loadingGoogle,
    loadingFacebook: state => !!state.loadingFacebook,
    loadingTwitter: state => !!state.loadingTwitter,
    loadingInit: state => !!state.loadingInit,
    loadingEmailConfirmation: state => !!state.loadingEmailConfirmation,
    loadingPasswordResetEmail: state => !!state.loadingPasswordResetEmail,
    loadingUpdateProfile: state => !!state.loadingUpdateProfile,
    loadingChangeMyPassword: state => !!state.loadingChangeMyPassword,

    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName
      }

      return getters.currentUser.email.split('@')[0]
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatar
        // !getters.currentUser.avatars ||
        // !getters.currentUser.avatars.length ||
        // !getters.currentUser.avatars[0].publicUrl
      ) {
        // let defaultUrl= '../../../public/images/imgUploader.svg'
        return null
      }

      return getters.currentUser.avatar.publicUrl
      // return getters.currentUser.avatars[0].publicUrl;
    },

    cuurentUserSocialAvatar: (state, getters) => {
      return getters.currentUserSocial
    }
  },

  mutations: {
    AUTH_START(state, payload) {
      switch (payload) {
        case 'google':
          state.loadingGoogle = true
          break
        case 'facebook':
          state.loadingFacebook = true
          break
        case 'twitter':
          state.loadingTwitter = true
          break
        default:
          state.loading = true
          break
      }
    },
    AUTH_SUCCESS(state, payload) {
      state.authenticationUser = payload.authenticationUser || null
      state.currentUser = payload.currentUser || null
      state.currentUserSocial = payload.currentUserSocial || null
      state.loading = false
      state.loadingGoogle = false
      state.loadingFacebook = false
      state.loadingTwitter = false
    },
    AUTH_ERROR(state) {
      state.authenticationUser = null
      state.currentUser = null
      state.loading = false
      state.loadingGoogle = false
      state.loadingFacebook = false
      state.loadingTwitter = false
    },

    EMAIL_CONFIRMATION_START(state) {
      state.loadingEmailConfirmation = true
    },
    EMAIL_CONFIRMATION_SUCCESS(state) {
      state.loadingEmailConfirmation = false
    },
    EMAIL_CONFIRMATION_ERROR(state) {
      state.loadingEmailConfirmation = false
    },

    PASSWORD_RESET_START(state) {
      state.loadingPasswordResetEmail = true
    },
    PASSWORD_RESET_SUCCESS(state) {
      state.loadingPasswordResetEmail = false
    },
    PASSWORD_RESET_ERROR(state) {
      state.loadingPasswordResetEmail = false
    },

    UPDATE_PROFILE_START(state) {
      state.loadingUpdateProfile = true
    },
    UPDATE_PROFILE_SUCCESS(state) {
      state.loadingUpdateProfile = false
    },
    UPDATE_PROFILE_ERROR(state) {
      state.loadingUpdateProfile = false
    },

    CHANGE_MY_PASSWORD_START(state) {
      state.loadingChangeMyPassword = true
    },
    CHANGE_MY_PASSWORD_SUCCESS(state) {
      state.loadingChangeMyPassword = false
    },
    CHANGE_MY_PASSWORD_ERROR(state) {
      state.loadingChangeMyPassword = false
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser = payload.authenticationUser || null
      state.currentUser = payload.currentUser || null
      state.loadingInit = false
    },
    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null
      state.currentUser = null
      state.loadingInit = false
    },
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null
    }
  },

  actions: {
    //#region [ Init ]
    doInitFirebase() {
      service.init()
    },
    async doInit({ commit, dispatch }) {
      service.init()
      let currentUser = service.fetchLocalCurrentUser()
      if (!currentUser) {
        commit('AUTH_INIT_SUCCESS', {
          currentUser: null,
          authenticationUser: null
        })
        // ProgressBar.done();
        return
      }

      const unsubscribe = service.onAuthStateChanged(
        authenticationUser => {
          dispatch('doSigninFromAuthChange', authenticationUser)
          unsubscribe()
        },
        error => {
          console.error(error)
          commit('AUTH_INIT_ERROR')
        }
      )
    },
    async doWaitUntilInit({ getters }) {
      if (!getters.loadingInit) {
        return Promise.resolve()
      }

      return new Promise(resolve => {
        const waitUntilInitInterval = setInterval(() => {
          if (!getters.loadingInit) {
            clearInterval(waitUntilInitInterval)
            resolve()
          }
        }, 500)
      })
    },
    //#endregion

    //#region [ Listeners ]
    // async doRunCurrentUserChangedListener({ getters, dispatch }) {
    //   try {
    //     const currentUser = getters.currentUser
    //     const db = firebase.firestore()
    //     onSnapshot(doc(db, 'users', currentUser.id), doc => {
    //       const user = doc.data()
    //       if (
    //         !user ||
    //         user.status == 'disabled' ||
    //         user.status == 'removed' ||
    //         user.disabled
    //       ) {
    //         dispatch('doSignout')
    //       }
    //       dispatch('doRefreshCurrentUser')
    //     })
    //   } catch (error) {
    //     throw error
    //   }
    // },
    //#endregion

    //#region [ Send Email ]
    async doSendEmailConfirmation({ commit, getters }) {
      try {
        commit('EMAIL_CONFIRMATION_START')
        await service.sendEmailVerification(getters.authenticationUser)
        // Message.success(i18n('auth.verificationEmailSuccess'));
        commit('EMAIL_CONFIRMATION_SUCCESS')
      } catch (error) {
        Errors.handle(error)
        commit('EMAIL_CONFIRMATION_ERROR')
      }
    },

    async doSendPasswordResetEmail({ commit }, email) {
      try {
        commit('PASSWORD_RESET_START')
        await service.sendPasswordResetEmail(email)
        Message.success(i18n('auth.passwordResetEmailSuccess'))
        commit('PASSWORD_RESET_SUCCESS')
      } catch (error) {
        // Message.error(error);
        Errors.handle(error)
        commit('PASSWORD_RESET_ERROR')
      }
    },
    async doSigninWithEmailAndPassword(
      { commit },
      { email, password, rememberMe }
    ) {
      try {
        commit('AUTH_START')

        let authenticationUser = null
        let currentUser = null
        const credentials = await service.signinWithEmailAndPassword(
          email,
          password,
          rememberMe
        )

        if (credentials && credentials.user) {
          authenticationUser = credentials.user
          currentUser = await service.fetchMe()
          currentUser.emailVerified = !!authenticationUser.emailVerified
        }

        service.reauthenticateWithStorageToken()
        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser
        })
        service.saveLocalCurrentUser(currentUser)
        router.push(`/`)
      } catch (error) {
        await service.signout()
        Errors.handle(error)
        commit('AUTH_ERROR')
      }
    },
    async doSignout({ commit }) {
      try {
        commit('AUTH_START')
        await service.signout()
        localStorage.clear()
        commit('AUTH_SUCCESS', {
          authenticationUser: null,
          currentUser: null
        })
        router.push('/auth/login')
      } catch (error) {
        Errors.handle(error)
        commit('AUTH_ERROR')
      }
    },

    async doSigninFromAuthChange({ commit }, authenticationUser) {
      try {
        let currentUser = await service.fetchMe()
        service.saveLocalCurrentUser(currentUser)
        if (authenticationUser) {
          service.reauthenticateWithStorageToken() // In background
          currentUser.emailVerified = !!authenticationUser.emailVerified
        }
        commit('AUTH_INIT_SUCCESS', {
          currentUser,
          authenticationUser: authenticationUser
        })
      } catch (error) {
        service.signout()
        Errors.handle(error)
        commit('AUTH_INIT_ERROR', error)
      }
    },

    async doRefreshCurrentUser({ commit, getters }) {
      try {
        const authenticationUser = getters.authenticationUser
        const currentUser = await service.fetchMe()
        service.saveLocalCurrentUser(currentUser)
        currentUser.emailVerified = !!authenticationUser.emailVerified
        service.reauthenticateWithStorageToken() // in background
        commit('CURRENT_USER_REFRESH_SUCCESS', { currentUser })
      } catch (error) {
        service.signout()
        Errors.handle(error)
        commit('CURRENT_USER_REFRESH_ERROR', error)
      }
    },

    async doUpdateProfile(
      { commit, dispatch },
      { firstName, lastName, phoneNumber, avatar, email, pagesAccess, lang }
    ) {
      try {
        commit('UPDATE_PROFILE_START')
        let currentUser = service.fetchLocalCurrentUser()

        if (currentUser) {
          currentUser.firstName = firstName || currentUser.firstName
          currentUser.lastName = lastName || currentUser.lastName
          currentUser.phoneNumber = phoneNumber || currentUser.phoneNumber
          currentUser.avatar = avatar || currentUser.avatar
          currentUser.email = email || currentUser.email
          currentUser.pagesAccess = pagesAccess || currentUser.pagesAccess
          currentUser.lang = lang || currentUser.lang

          service.saveLocalCurrentUser(currentUser)
        }

        await service.updateProfile({
          firstName,
          lastName,
          phoneNumber,
          avatar,
          email,
          pagesAccess,
          lang
        })

        commit('UPDATE_PROFILE_SUCCESS')
        await dispatch('doRefreshCurrentUser')
        Message.success(i18n('auth.profile.success'))
        router.push('/')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_PROFILE_ERROR')
      }
    },

    async doChangeMyPassword({ commit }, { oldPassword, newPassword }) {
      try {
        commit('CHANGE_MY_PASSWORD_START')
        await service.changeMyPassword(oldPassword, newPassword)
        commit('CHANGE_MY_PASSWORD_SUCCESS')
        Message.success(i18n('auth.profile.changePassword'))
        router.push('/profile')
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_MY_PASSWORD_ERROR')
      }
    },

    async verifyOwner({ commit }) {
      try {
        const response = await FirebaseRepository.findDocument(
          '--sharedInfo--',
          '--owner--'
        )
        const isExist = response ? response.isExist : false
        commit('VERIFY_OWNER', isExist)
      } catch (error) {
      }
    }
  }
}
