import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  sliderCreate: {
    id: 'sliderCreate',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileSliderEditor
    ]
  },
  sliderEdit: {
    id: 'sliderEdit',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileSliderEditor
    ]
  },
  sliderDestroy: {
    id: 'sliderDestroy',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileSliderEditor
    ]
  },
  sliderImport: {
    id: 'sliderImport',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileSliderEditor
    ]
  },
  sliderRead: {
    id: 'sliderRead',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.mobileSliderEditor,
      roles.mobileSliderViewer
    ]
  },
  sliderUserAutocomplete: {
    id: 'sliderUserAutocomplete',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.mobileSliderEditor,
      roles.mobileSliderViewer
    ]
  }
}

export default Permissions
