/* eslint-disable */
// import { storeAsync } from '@/app-module';
// const store = storeAsync();
import { useStore } from '@/store'

export default {
  async beforeRouteEnter(to, from, next) {
    debugger
    if (!to.meta || !to.meta.auth) {
      next()
      return
    }

    const store = useStore()

    let currentUser = localStorage.getItem('currentUser')
    if (currentUser) {
      await store.dispatch('auth/doWaitUntilInit')
    }

    if (!store.getters['auth/signedIn']) {
      next({ path: 'auth/login' })
      return
    }

    // if (
    //   to.path !== '/auth/email-unverified' &&
    //   !store.getters['auth/currentUser'].emailVerified
    // ) {
    //   next({ path: '/auth/email-unverified' });
    //   return;
    // }

    if (
      to.path !== '/auth/empty-permissions' &&
      store.getters['auth/currentUser'].emailVerified &&
      !store.getters['auth/accountType'] === 'admin'
      // !store.getters['auth/roles'].length
    ) {
      next({ path: '/auth/empty-permissions' })
      return
    }

    next()
  }
}
