<template>
  <div>
    <!-- <div class="switch"> -->
    <div
      class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
    >
      <input
        type="checkbox"
        name="toggle"
        :id="id"
        class="transition-transform duration-200 toggle-checkbox absolute block w-6 h-6 rounded-full bg-white appearance-none cursor-pointer"
        :checked="active"
        :dir="store.getters['layout/isRTL'] ? 'rtl' : 'ltr'"
        @click="handleClick"
      />
      <label
        :for="id"
        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
      ></label>
    </div>
    <label for="toggle" class="text-xs text-gray-700" v-if="title"
      >{{ title }}
    </label>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { v4 } from 'uuid'
import { useStore } from 'vuex'

const id = v4()

const props = defineProps({
  title: {
    type: String,
    default: undefined
  },
  active: {
    type: Boolean,
    default: false,
    required: true
  },
  action: {
    type: Function
  }
})
const store = useStore()

const handleClick = () => {
  typeof props.action !== 'undefined' ? props.action() : undefined
}
</script>
<style scoped>
.toggle-checkbox[dir='ltr'] {
  right: 0;
  left: auto;
}
.toggle-checkbox[dir='rtl'] {
  left: 0;
  right: auto;
}

.toggle-checkbox {
  border: solid 0.15rem rgba(209, 213, 219, var(--tw-bg-opacity));
}
.toggle-checkbox:checked {
  --tw-border-opacity: 1;
  border: solid 0.15rem #68d391;
}

.toggle-checkbox:checked[dir='ltr'] {
  @apply: border-green-400;
  right: auto;
  left: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked[dir='rtl'] {
  left: auto;
  right: 0;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68d391;
}
</style>
