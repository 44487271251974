/* eslint-disable */ 
import categoryListStore from '@/store/category/category-list-store';
import categoryFormStore from '@/store/category/category-form-store';
import categoryDestroyStore from '@/store/category/category-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: categoryListStore,
    form: categoryFormStore,
    destroy: categoryDestroyStore
  },
};
