/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSlider)

export class SliderService {
  //#region [ Mutation APIs ]

  /**
   * Create new slider
   * - addSlider(sliderInput: SliderInput!): JSON!
   * @param {Object} data
   * @param {String} data.id
   * @param {String} data.imageEn
   * @param {String} data.imageAr
   * @param {Object} data.title
   * @param {String} data.title.en
   * @param {String} data.title.ar
   * @param {Object} data.description
   * @param {String} data.description.en
   * @param {String} data.description.ar
   * @returns {Promise}
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE($data: SliderInput!) {
          sliderCreate(data: $data)
        }
      `,

variables: {
  data
}
})

return response.data.sliderCreate
// if (response.data.sliderCreate && response.data.sliderCreate.status) {
  //   return response.data.sliderCreate.result
  // } else {
    //   console.error(response.data.sliderCreate.error)
    //   throw response.data.sliderCreate.error
    // }
  }
  
  /**
   * Update slider
   * - updateSlider(sliderId: String!, sliderUpdate: SliderUpdate!): JSON!
   * @param {String} id - Slider ID
   * @param {Object} data
   * @param {String} data.imageEn
   * @param {String} data.imageAr
   * @param {Object} data.title
   * @param {String} data.title.en
   * @param {String} data.title.ar
   * @param {Object} data.description
   * @param {String} data.description.en
   * @param {String} data.description.ar
   * @returns {Promise}
  */
 
 static async update(id, data) {
   const response = await graphqlClient.mutate({
     mutation: gql`
        mutation UPDATE($id: String!, $data: SliderInput!) {
          sliderUpdate(id: $id, data: $data)
        }
      `,
      variables: {
        id,
        data
      }
    })

    return response.data.sliderUpdate
    // if (response.data.sliderUpdate && response.data.sliderUpdate.status) {
    //   return response.data.sliderUpdate.result
    // } else {
    //   console.error(response.data.sliderUpdate.error)
    //   throw response.data.sliderUpdate.error
    // }
  }

  /**
   * Delete slider by ID
   * - removeSlider(sliderId: String!): JSON!
   * @param {String} sliderId
   * @returns {Promise}
   */
  static async remove(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DESTROY($id: String!) {
          sliderDestroy(id: $id)
        }
      `,

      variables: {
        id
      }
    })
    return response.data.sliderDestroy
    // if (response.data.sliderDestroy && response.data.sliderDestroy.status) {
    //   return response.data.sliderDestroy.result
    // } else {
    //   console.error(response.data.sliderDestroy.error)
    //   throw response.data.sliderDestroy.error
    // }
  }
  //#endregion

  //#region [ Query APIs ]
  /**
   * Find Slider By ID
   * - findSliderById(sliderId: String!): Slider!
   * @param {String} id - Slider ID
   * @returns {Promise<Slider>}
   */
  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query FIND($id: String!) {
          sliderFind(id: $id) {
            id
            imageEn {
              name
              publicUrl
            }
            imageAr {
              name
              publicUrl
            }
            link
            redirect_item_id
            redirect_item_type
            linkAction
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `,

      variables: {
        id
      }
    })

    return response.data.sliderFind
  }

  /**
   * List all sliders
   * - listSliders: [Slider]!
   * @returns {Promise<Slider[]>}
   */
  static async list() {
    const response = await graphqlClient.query({
      query: gql`
        query LIST {
          sliderList {
            id
            imageEn {
              name
              publicUrl
            }
            imageAr {
              name
              publicUrl
            }
            link
            linkAction
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
      `
    })

    return response.data.sliderList
  }
  //#endregion
}
