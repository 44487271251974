const firebaseConfig = {
  apiKey: 'AIzaSyDCdTWyEHCBT0yocHTK5dJcuuQoJXgVIWw',
  authDomain: 'shamy-stores-8a2dd.firebaseapp.com',
  databaseURL: 'https://shamy-stores-8a2dd-default-rtdb.firebaseio.com',
  projectId: 'shamy-stores-8a2dd',
  storageBucket: 'shamy-stores-8a2dd.appspot.com',
  messagingSenderId: '1094629850015',
  appId: '1:1094629850015:web:a6f3c036d6ccc6e58d9baa',
  measurementId: 'G-5BPHYBKFT5'
}

const REGION = 'europe-west1'
const OLD_BASE_URL = `https://${REGION}-${firebaseConfig.projectId}.cloudfunctions.net`
const NEW_BASE_URL = `https://shamy-production-3h3kqnoxna-ey.a.run.app`

const backendUrl = NEW_BASE_URL
const backendUrlAuth = `${NEW_BASE_URL}/auth`
const backendUrlAdmin = `${NEW_BASE_URL}/admin`
const backendUrlPortal = `${NEW_BASE_URL}/portal-user`
const backendUrlCustomer = `${NEW_BASE_URL}/app-customer`
const backendUrlCategory = `${ OLD_BASE_URL}/category`
const backendUrlProduct = `${ OLD_BASE_URL}/product`
const backendUrlOrder = `${ OLD_BASE_URL}/orders`
const backendUrlWallet = `${ OLD_BASE_URL}/wallet`
const backendUrlSettings = `${NEW_BASE_URL}/settings`
const backendUrlSlider = `${NEW_BASE_URL}/slider`
const backendUrlSidebar = `${NEW_BASE_URL}/app-sidebar`
const backendUrlAuction = `${NEW_BASE_URL}/app-auction`
const backendUrlNotify = `${NEW_BASE_URL}/notification`

export default {
  firebaseConfig,
  REGION,
  BASE_URL: NEW_BASE_URL,
  backendUrl,
  backendUrlAuth,
  backendUrlAdmin,
  backendUrlPortal,
  backendUrlCustomer,
  backendUrlCategory,
  backendUrlProduct,
  backendUrlOrder,
  backendUrlWallet,
  backendUrlSettings,
  backendUrlSidebar,
  backendUrlSlider,
  backendUrlAuction,
  backendUrlNotify
}
