/* eslint-disable */ 
import productListStore from '@/store/product/product-list-store';
import productFormStore from '@/store/product/product-form-store';
import productViewStore from '@/store/product/product-view-store';
import productDestroyStore from '@/store/product/product-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: productListStore,
    form: productFormStore,
    view: productViewStore,
    destroy: productDestroyStore
  },
};
