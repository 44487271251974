/* eslint-disable */
import { UserService } from '@/store/user/user-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  sortBy: 'desc'
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    isFirstPage: null,
    isLastPage: null,
    loading: false,
    filter: [],
    currentPage: 1,

    pagination: INITIAL_PAGINATION_INPUT,
    sorter: {},
    table: null,
    type: null
  },

  getters: {
    loading: state => state.loading,
    exportLoading: state => state.exportLoading,
    rows: state => state.rows || [],
    count: state => state.count,
    isFirstPage: state => state.isFirstPage,
    isLastPage: state => state.isLastPage,
    hasRows: (state, getters) => getters.count > 0,
    filter: state => state.filter,
    currentPage: state => state.currentPage,
    type: state => state.type,
    table: state => state.table,
    orderBy: state => {
      const sorter = state.sorter

      if (!sorter) {
        return null
      }

      if (!sorter.prop) {
        return null
      }

      let direction = sorter.order === 'descending' ? 'DESC' : 'ASC'

      return `${sorter.prop}_${direction}`
    },

    limit: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }

      return pagination.pageSize
    },

    offset: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return 0
      }

      const currentPage = pagination.currentPage || 1

      return (currentPage - 1) * pagination.pageSize
    },

    pagination: state => state.pagination,

    selectedRows: state => {
      return state.table ? state.table.selection : []
    }
  },

  mutations: {
    RESETED(state) {
      state.rows = []
      state.count = 0
      state.loading = false
      state.filter = []
      state.pagination = INITIAL_PAGINATION_INPUT
      state.sorter = {}
      state.currentPage = 1
      state.type = null
      if (state.table) {
        state.table.clearSelection()
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection()
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT

      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {}
    },

    FETCH_STARTED(state, payload) {
      state.loading = true
      state.rows = []
      state.type = payload && payload.type ? payload.type : state.type
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
      state.isFirstPage = payload.isFirstPage
      state.isLastPage = payload.isLastPage
    },

    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
      state.count = 0
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false
    },

    REMOVE_ALL_SELECTED_STARTED(state) {
      state.loading = true
    },

    REMOVE_ALL_SELECTED_ERROR(state) {
      state.loading = false
    },

    REMOVE_ALL_SELECTED_SUCCESS(state) {
      if (state.table) {
        state.table.clearSelection()
      }
    },

    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true
    },

    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false
    },

    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      state.loading = false
    },
    UPDATE_USER_TYPE(state, payload) {
      state.type = payload
    }
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL')
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED')
      return dispatch('doFetch')
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.pagination
      const type = getters.type
      dispatch('doFetch', { filter, pagination, type, keepPagination: true })
    },

    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
    },

    doSetUserType({ commit }, type) {
      commit('UPDATE_USER_TYPE', type)
    },

    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'next',
          page: currentPage
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination, type: getters.type })
      }
    },

    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'prev',
          page: currentPage
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination, type: getters.type })
      }
    },
    async doFetch(
      { commit, getters },
      { filter, pagination, type, keepPagination = true, orderBy } = {}
    ) {
      try {
        if (type !== 'admin' && type !== 'customer' && type !== 'portal') return

        commit('FETCH_STARTED', {
          filter,
          pagination,
          type,
          keepPagination,
          orderBy
        })

        const PaginationInput = {
          ...pagination,
          limit: pagination?.limit || getters.pagination.limit,
          sortBy: pagination?.sortBy || getters.pagination.sortBy
        }

        const response =
          type === 'admin'
            ? await UserService.fetchAdmins(
                getters.filter,
                PaginationInput,
                orderBy
              )
            : type === 'customer'
            ? await UserService.fetchCustomers(
                getters.filter,
                PaginationInput,
                orderBy
              )
            : await UserService.fetchPortals(
                getters.filter,
                PaginationInput,
                orderBy
              )

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
          isFirstPage: response.pagination?.isFirstPage,
          isLastPage: response.pagination?.isLastPage
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },
    async doDisableAllSelected({ getters, commit, dispatch }, ids) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')
        const type = getters.type
        if (type === 'admin' || type === 'portal') {
          await UserService.disableAdmins(ids)
        } else {
          await UserService.disableCustomers(ids)
        }

        commit('CHANGE_STATUS_SELECTED_SUCCESS')
        Message.success(i18n('user.doDisableAllSuccess', type))
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type: getters.type
        })
      }
    },
    async doEnableAllSelected({ commit, getters, dispatch }, ids) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')
        const type = getters.type
        if (type === 'admin' || type === 'portal') {
          await UserService.enableAdmins(ids)
        } else {
          await UserService.enableCustomers(ids)
        }

        commit('CHANGE_STATUS_SELECTED_SUCCESS')
        Message.success(i18n('user.doEnableAllSuccess', type))
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type
        })
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type: getters.type
        })
      }
    },
    async doDestroy({ dispatch, getters }, id) {
      try {
        if (getters.type === 'admin') {
          await UserService.destroyAdmins(id)
        } else if (getters.type === 'portal') {
          await UserService.destroyPortalAdmins(id)
        } else {
          await UserService.destroyCustomers(id)
        }

        await dispatch('doFetch', {
          filter: getters.filter,
          pagination: getters.pagination,
          type: getters.type
        })
        Message.success(i18n('user.destroy.success', [getters.type]))
      } catch (error) {
        Errors.handle(error)
      }
    }
  }
}
