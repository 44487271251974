/* eslint-disable */ // import { routerAsync } from '@/app-module';
import { WalletService } from '@/store/wallet/wallet-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    record: null,
    loading: false
  },

  getters: {
    record: state => state.record,
    loading: state => !!state.loading
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null
      state.loading = true
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload
      state.loading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.loading = false
    },
    REFRESH_STARTED(state) {
      state.loading = true
    },
    REFRESH_SUCCESS(state, payload) {
      state.record = payload
      state.loading = false
    },
    REFRESH_ERROR(state) {
      state.loading = false
    }
  },

  actions: {
    async doFind({ commit }) {
      try {
        commit('FIND_STARTED')
        const record = await WalletService.find()
        commit('FIND_SUCCESS', record.reverse())
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/wallet')
      }
    },
    async doRefresh({ commit }) {
      try {
        commit('REFRESH_STARTED')
        const record = await WalletService.refresh()
        commit('REFRESH_SUCCESS', record.reverse())
      } catch (error) {
        Errors.handle(error)
        commit('REFRESH_ERROR')
        router.push('/wallet')
      }
    }
  }
}
