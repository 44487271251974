/* eslint-disable */ 
// import walletListStore from '@/store/wallet/wallet-list-store';
// import walletFormStore from '@/store/wallet/wallet-form-store';
import walletViewStore from '@/store/wallet/wallet-view-store';
// import walletDestroyStore from '@/store/wallet/wallet-destroy-store';
// import walletTransactionStore from '@/store/wallet/wallet-transaction-store';

export default {
  namespaced: true,
  modules: {
    // list: walletListStore,
    // form: walletFormStore,
    view: walletViewStore,
    // destroy: walletDestroyStore
    // transaction: walletTransactionStore
  },
};
