<template>
  <div>
    <router-view />
    <ErrorNotify message="--text--"></ErrorNotify>
    <SuccessNotify message="--text--"></SuccessNotify>
    <!-- <SuccessNotifyWithButtons></SuccessNotifyWithButtons> -->
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { getLanguageCode } from '@/locales/i18n'

export default defineComponent({
  async created() {
    await this.doInit()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    ...mapGetters({
      language: 'layout/currentLanguageCode'
    })
  },
  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize'
    }),
    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
  },
  setup() {
    onMounted(() => {
      const language = getLanguageCode() || localStorage.getItem('language')
      if (language == 'ar') {
        document.body.setAttribute('dir', 'rtl')
      } else {
        document.body.setAttribute('dir', 'ltr')
      }
    })
  }
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  background-color: #646a7b;
}
[dir='rtl'] body,
body[dir='rtl'] * {
  font-family: 'Noto Kufi Arabic', sans-serif !important;
}
[dir='ltr'] body,
body[dir='ltr'] * {
  font-family: 'Poppins', sans-serif !important;
}
.vue-tel-input {
  direction: ltr;
}
.dropzone {
  height: fit-content !important;
}

.items .ts-input .not-full .has-items {
  background: red !important;
}
</style>
