<template>
  <div style="position: relative !important;">
    <div class="input-icon">
      <!-- :value="email" -->
      <!-- @input="$emit('update:email', $event.target.value)" -->
      <input
        v-model="query"
        @keyup="getData()"
        @input="OnSearch(this)"
        type="text"
        autocomplete="off"
        :placeholder="placeholder"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        class="w-100"
        :class="inputClass"
        ref="input_search"
      />
      <XIcon class="w-4 h-4 icon" @click="clearInput()" v-if="query" />
    </div>

    <div class="panel-footer" v-if="(optionsList.length || loading || noResults) && !close">
      <ul class="list-group scroll" v-if="optionsList.length">
        <a href="#" class="list-group-item list-group-item-hover"
          v-for="(option, index) in optionsList" :key="index" @click="setSelectedOption(option)"
        >{{ option[label] }}</a>
      </ul>
      <div class="list-group-item" v-if="noResults && !loading">
        {{ isRTL ? 'عذرا، لم يتم العثور على نتائج.' : 'Sorry, but no results were found.' }}
      </div>
      <div class="list-group-item" v-if="loading">
        <i>{{ isRTL ? 'جاري البحث٫٫٫' : 'Searching...' }}</i>
      </div>
    </div>

    <div class="row" id="multiple-data" v-if="multiple">
      <div class="col-md-6 mb-5" v-for="(item, index) in list" :key="index">
        <div class="form-check-inline d-flex justify-content-between align-items-center list-item">
          <div class="d-flex align-items-center" style="width: calc(100% - 2.143rem);">
            <input class="" type="checkbox" :id="item.id" v-model="item.checked">
            <label class="form-check-label ellipsis" :for="item.id">{{ item.name }}</label>
          </div>
          <i class='bx bx-trash icon-delete' :style="item.checked ? 'opacity: 1 !important;' : 'opacity: 0.5;'" @click="item.checked ? removeItem(index) : ''"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { EventBus } from '@/event-bus'

export default {
  name: 'v-auto-complete',

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {},
    options: {
      type: Array,
      default: () => []
    },
    inputClass: {
      type: String
    },
    charCount: {
      type: Number,
      default: 0
    },
    label: {
      type: String
    },
    target: {
      type: String
    },
    placeholder: {
      type: String
    }
  },
  data() {
    return {
      list: [],
      query: '',
      noResults: false,
      val: '',
      close: true
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL'
    }),
    // valueChanged: {
    //   get() {
    //     return this.val;
    //   },
    //   set(newValue) {
    //     this.val = newValue;
    //   }
    // },
    optionsList() {
      return this.query && this.query.length >= this.charCount
        ? this.options
        : []
    }
  },
  mounted() {
    // EventBus.$on('resetAutoComplete', this.reset)
    this.query = this.value ? this.value[this.label] : ''
    if (this.options && this.options.length) {
      const option = this.options[0]
      this.query = option.label
    }
  },
  methods: {
    updateValue(value) {
      // debugger
      if (this.target) {
        this.$emit('input', value[this.target])
      } else {
        this.$emit('input', value)
      }
    },
    OnSearch(input) {
      // debugger
      if (input && input.value == '') {
        this.list = []
      }
    },
    getData() {
      if (!this.multiple) {
        this.updateValue('')
      }
      if (this.query.length >= this.charCount) {
        this.$emit('change', this.query)
        this.close = false
        // this.$nextTick(() => {
        //   this.noResults = this.optionsList.length === 0
        // })
      } else {
        // this.options = []
        this.noResults = false
      }
    },

    setSelectedOption(option) {
      // debugger
      if (this.multiple) {
        this.query = ''
        this.list.push({
          id: option.id,
          name: option[this.label],
          checked: false
        })
        // this.updateValue(this.list)
        this.$emit('onSelect', this.list)
        // input_search
        this.close = true
        this.noResults = false
      } else {
        // this.updateValue(option)
        this.$emit('onSelect', option)
        this.query = option[this.label]
        //   this.valueChanged = option[this.label]
        //   this.value = option
        this.close = true
        this.noResults = false
      }
    },
    removeItem(index) {
      this.list.splice(index, 1)
    },
    clearInput() {
      this.query = ''
      if (!this.multiple) {
        this.updateValue('')
        this.$emit('onSelect', '')
      }
    },
    reset() {
      this.query = ''
      this.list = []
      this.noResults = false
      this.val = ''
      this.close = true
      // this.updateValue('')
    }
  },
  watch: {
    optionsList() {
      this.noResults = this.optionsList.length === 0 && this.query.length >= this.charCount
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel-footer {
    padding: 10px 15px !important;
    background-color: #f5f5f5 !important;
    /* border-top: 1px solid #ddd !important; */
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    position: absolute !important;
    margin-top: 5px !important;
    /* right: 18px !important; */
    /* left: 18px !important; */
    right: 0px !important;
    left: 0px !important;
    z-index: 1200 !important;
    /* display: none !important;/ */
    /* float: left !important; */
    /* min-width: 160px !important; */
    /* padding: 5px 0 !important; */
    /* margin: 5px 0 0 !important;  */
    font-size: 1rem !important;
    color: #76838f !important;
    text-align: left !important;
    list-style: none !important;
    /* background-color: #fff !important; */
    background-clip: padding-box !important;
    border: 1px solid #e4eaec !important;
    border-radius: 0.215rem !important;
    box-shadow: 0 3px 12px rgb(0 0 0 / 5%) !important;
    transition: .25s !important;
    transform: translate3d(0px, 5px, 1px) !important;
  }
  .list-group {
    padding-left: 0 !important;
    /* margin-bottom: 20px !important; */
    margin-bottom: 0 !important;
    overflow-y: auto !important;
    max-height: 316px !important;
  }
  ol, ul {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }
  .list-group-item {
    position: relative !important;
    display: block !important;
    padding: 10px 15px !important;
    margin-bottom: -1px !important;
    background-color: #fff !important;
    border: 1px solid #ddd !important;
  }
  .list-group-item-hover:hover {
    background: rgb(253 253 253) !important;
    font-weight: bold !important;
  }
  a.list-group-item,
  button.list-group-item {
    color: #555 !important;
  }
  .list-group-item:first-child {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
  .list-group-item:last-child {
    margin-bottom: 0 !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
  /* width */
  .scroll::-webkit-scrollbar {
    width: 5px !important;
  }
  /* Track */
  .scroll::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }
  /* Handle */
  .scroll::-webkit-scrollbar-thumb {
    background: #c2c0c0 !important;
  }
  /* Handle on hover */
  .scroll::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }
  #multiple-data {
    margin-top: 25px !important;
  }
  .icon-delete {
    transition: all .5s ease !important;
    margin-left: 30px !important;
    color: red !important;
    opacity: 0.5 !important;
    cursor: pointer !important;
  }
  .list-item {
    transition: all .35s ease !important;
    cursor: pointer !important;
  }
  /* .list-item:hover .icon-delete {
    transform: scale(1.2) !important;
    opacity: 0.5 !important;
  } */
  .input-icon {
    position: relative !important;
  }
  .input-icon input {
    padding-right: 40px !important;
  }
  .input-icon i {
    position: absolute !important;
  }
  .input-icon .icon {
    position: absolute !important;
    font-size: 16px !important;
    padding: 14px 10px !important;
    min-width: 40px !important;
    height: 100% !important;
    top: 0 !important;
    right: 0 !important;
    cursor: pointer !important;
    color: #f18b43 !important;
    font-size: 1.25rem !important;
    opacity: 0.6 !important;
  }
</style>
