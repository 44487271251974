/* eslint-disable */
import { CategoryService } from '@/store/category/category-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  sortBy: 'desc'
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    isFirstPage: null,
    isLastPage: null,
    filter: [],
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT,
    sorter: {},
    table: null
    // type: null,
  },

  getters: {
    loading: state => state.loading,
    rows: state => state.rows || [],
    count: state => state.count,
    isFirstPage: state => state.isFirstPage,
    isLastPage: state => state.isLastPage,
    hasRows: (state, getters) => getters.count > 0,
    table: state => state.table,
    filter: state => state.filter,
    currentPage: state => state.currentPage,
    pagination: state => state.pagination,
    limit: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }

      return pagination.pageSize
    },
    selectedRows: state => {
      return state.table ? state.table.selection : []
    }
  },

  mutations: {
    RESETED(state) {
      state.rows = []
      state.count = 0
      state.loading = false
      state.filter = []
      state.pagination = INITIAL_PAGINATION_INPUT
      state.sorter = {}
      state.currentPage = 1
      if (state.table) {
        state.table.clearSelection()
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection()
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1

      // const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT;
      // state.pagination = {
      //   ...previousPagination,
      //   currentPage: payload || 1,
      //   pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      // };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT

      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
        // currentPage: previousPagination.currentPage || 1,
        // pageSize: payload || INITIAL_PAGE_SIZE,
      }
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {}
    },

    FETCH_STARTED(state, payload) {
      state.loading = true
      if (state.table) {
        state.table.clearSelection()
      }

      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
      state.isFirstPage = payload.isFirstPage
      state.isLastPage = payload.isLastPage
    },

    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
      state.count = 0
    },
    ADD_STARTED(state) {
      state.loading = true
    },
    ADD_SUCCESS(state) {
      state.loading = false
    },
    ADD_ERROR(state) {
      state.loading = false
    },

    // REMOVE_ALL_SELECTED_STARTED(state) {
    //   state.loading = true;
    // },

    // REMOVE_ALL_SELECTED_ERROR(state) {
    //   state.loading = false;
    // },

    // REMOVE_ALL_SELECTED_SUCCESS(state) {
    //   if (state.table) {
    //     state.table.clearSelection();
    //   }
    // },

    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true;
    },

    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false;
    },

    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      state.loading = false;
    },
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL')
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table)
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED')
      return dispatch('doFetch')
    },

    // doChangeSort({ commit, getters, dispatch }, sorter) {
    //   commit('SORTER_CHANGED', sorter);
    //   const filter = getters.filter;
    //   dispatch('doFetch', { filter, keepPagination: true });
    // },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.pagination
      dispatch('doFetch', { filter, pagination, keepPagination: true })
    },

    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
      // const filter = getters.filter;
      // dispatch('doFetch', { filter, keepPagination: true });
    },
    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const lastDocument = rows[rows.length - 1]
        const pagination = {
          ...previousPagination,
          action: 'next',
          page: currentPage
          // firstPage: false,
          // doc: lastDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const firstDocument = rows[0]
        const pagination = {
          ...previousPagination,
          action: 'prev',
          page: currentPage
          // firstPage: false,
          // doc: firstDocument
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination })
      }
    },

    async doFetch(
      { commit, getters },
      { filter, pagination, keepPagination = true } = {}
    ) {
      try {
        commit('FETCH_STARTED', { filter, pagination, keepPagination })
        const PaginationInput = {
          ...pagination,
          limit: pagination?.limit || getters.pagination.limit,
          sortBy: pagination?.sortBy || getters.pagination.sortBy
        }
        const response = await CategoryService.listCategories(
          getters.filter,
          PaginationInput
        )
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
          isFirstPage: response.pagination?.isFirstPage,
          isLastPage: response.pagination?.isLastPage
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },

    async doAddCategories({ commit, dispatch }) {
      try {
        commit('ADD_STARTED')
        const response = await CategoryService.addCategories()
        await dispatch('doFetch')
        commit('ADD_SUCCESS')
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },
    async doToggleCategoryStatus({ commit, dispatch }, { id, enabled }) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')

        await CategoryService.toggleStatus(id, enabled)

        commit('CHANGE_STATUS_SELECTED_SUCCESS')

        enabled
          ? Message.success(i18n('category.activatedSuccessfully'))
          : Message.success(i18n('category.disabledSuccessfully'))
        dispatch('doFetch')
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch')
      }
    }
  }
}
