<template>
  <div class="h-full w-full flex items-center px-3">
    <div class="mx-auto text-center">
      <div class="flex-none image-fit rounded-full overflow-hidden mx-auto">
        <slot name="icon"></slot>
        <alert-triangle-icon :size="iconSize" class="custom-class" v-if="!$slots.icon"></alert-triangle-icon>
      </div>
      <div class="mt-3">
        <div class="font-large">
          <div :class="labelClass">
            <span v-html="label"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'app-empty-page',
  props: {
    label: {
      type: String,
      default: 'There is no data'
    },
    labelClass: {
      type: String,
      default: 'text-3xl font-medium leading-none'
    },
    iconSize: {
      type: String,
      default: '5x'
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style lang="scss" scoped>
.no-border {
  border: unset !important;
}
</style>
