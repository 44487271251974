/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlOrder)

const order = `
{
  id
  shopify_order_id
  shopify_order_name
  shopify_order_number
  shopify_total_price
  provider_total_price
  shopify_order_date
  shopify_order_closedAt
  currency
  ipAddress
  buyer_name
  buyer_email
  customer
  status
  env
  providers_name
  logs
  provider_orders {
      id
      zain_order_id
      refNumbers
      provider_products_serials
      provider_total_price
      provider_name
  }
  rejection_reason
  createdAt
}
`

export class OrderService {
  //#region [ Mutation APIs ]
  /**
   * Enable orders
   * @param {String[]} ids
   * @returns
   */
  static async enable(ids) {
    return this._changeStatus(ids, true)
  }

  /**
   * Disable orders
   * @param {String[]} ids
   * @returns
   */
  static async disable(ids) {
    return this._changeStatus(ids, false)
  }

  static async _changeStatus(ids, disabled) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PROMO_CODE_CHANGE_STATUS(
          $ordersIds: [String!]!
          $status: Boolean!
        ) {
          setPromoCodesStatus(ordersIds: $ordersIds, status: $status)
        }
      `,

      variables: {
        ordersIds: ids,
        status: !!disabled
      }
    })

    // return response.data.setPromoCodesStatus
    if (
      response.data.setPromoCodesStatus &&
      response.data.setPromoCodesStatus.status
    ) {
      return response.data.setPromoCodesStatus.result
    } else {
      console.error(response.data.setPromoCodesStatus.error)
      throw response.data.setPromoCodesStatus.error
    }
  }

  /**
   * Create new order
   * - addPromoCode(promoInput: PromoInput!): JSON!
   * @param {Object} data
   * @param {String} data.code
   * @param {DateTime} data.startDate
   * @param {DateTime} data.endDate
   * @param {Int} data.discount
   * @param {Int} data.maxUses
   *
   * @returns
   */
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PROMO_CODE_CREATE($promoInput: PromoInput!) {
          addPromoCode(promoInput: $promoInput)
        }
      `,

      variables: {
        promoInput: data
      }
    })

    // return response.data.addPromoCode
    if (response.data.addPromoCode && response.data.addPromoCode.status) {
      return response.data.addPromoCode.result
    } else {
      console.error(response.data.addPromoCode.error)
      throw response.data.addPromoCode.error
    }
  }

  /**
   * Delete order by ID
   * - orderDestroy(orderId: String!): Boolean
   * @param {String} id - order ID
   * @returns {Pro}
   */
  static async destroy(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ORDER_DESTROY($id: Float!) {
          orderDestroy(id: $id)
        }
      `,

      variables: {
        orderId: id
      }
    })

    return response.data.orderDestroy
  }
  //#endregion

  //#region [ Query APIs ]
  /**
   * Retrieve orders with pagination
   * - listPromos(pagination: PaginationInput!): [Promo]!
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   *
   * @returns {Promise<Promo[]>}
   */
  static async list(filter, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query ORDER_LIST(
          $filter: [FilterInput!]
          $orderBy: String
          $pagination: PaginationInput
        ) {
          orderList(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows ${order}
          }
        }
      `,

      variables: {
        pagination,
        filter,
        orderBy
      }
    })

    return response.data.orderList
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query ORDER_FIND($id: Float!) {
          orderFind(id: $id) ${order}
        }
      `,
      variables: {
        id
      }
    })
    return response.data.orderFind
  }

  static async resend(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ORDER_RESEND_SERIALS($id: Float!) {
          orderResendSerials(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.orderResendSerials
  }
  //#endregion
}
