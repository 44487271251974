import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import config from '@/config'
import { getAuth } from 'firebase/auth'

let appCheck = null

export default function firebaseInit() {
  const app = initializeApp(config.firebaseConfig)
  getAuth(app)
  if (
    process.env.VUE_APP_ENVIRONMENT === 'localhost' ||
    process.env.VUE_APP_ENVIRONMENT === 'development' ||
    process.env.VUE_APP_ENVIRONMENT === 'staging'
  ) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
  }
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      '6LcmC2knAAAAAO84psPr6vn9himW8sgggnHNVbFB'
    ),
    isTokenAutoRefreshEnabled: true
  })
}

export { appCheck }
