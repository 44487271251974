import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  notificationCreate: {
    id: 'notificationCreate',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.notificationEditor
    ]
  },
  notificationEdit: {
    id: 'notificationEdit',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.notificationEditor
    ]
  },
  notificationDestroy: {
    id: 'notificationDestroy',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.notificationEditor
    ]
  },
  notificationRead: {
    id: 'notificationRead',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.viewer,
      roles.notificationViewer,
      roles.notificationEditor
    ]
  }
}

export default Permissions
