/* eslint-disable */
import { SidebarService } from '@/store/sidebar/sidebar-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  sortBy: 'desc'
}

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    isFirstPage: null,
    isLastPage: null,
    filter: [],
    currentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT
  },

  getters: {
    loading: state => state.loading,
    rows: state => state.rows || [],
    count: state => state.count,
    isFirstPage: state => state.isFirstPage,
    isLastPage: state => state.isLastPage,
    hasRows: (state, getters) => getters.count > 0,
    filter: state => state.filter,
    currentPage: state => state.currentPage,
    pagination: state => state.pagination,
    limit: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }
      return pagination.pageSize
    }
  },

  mutations: {
    RESETED(state) {
      state.rows = []
      state.count = 0
      state.loading = false
      state.filter = []
      state.pagination = INITIAL_PAGINATION_INPUT
      state.sorter = {}
      state.currentPage = 1
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT

      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },

    FETCH_STARTED(state, payload) {
      state.loading = true
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
      state.isFirstPage = payload.isFirstPage
      state.isLastPage = payload.isLastPage
    },

    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
      state.count = 0
    },
    REMOVE_STARTED(state) {
      state.loading = true
    },
    REMOVE_SUCCESS(state) {
      state.loading = false
    },
    REMOVE_ERROR(state) {
      state.loading = false
    },
    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true
    },
    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false
    },
    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      state.loading = false
    }
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL')
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table)
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED')
      return dispatch('doFetch')
    },
    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.pagination
      dispatch('doFetch', { filter, pagination, keepPagination: true })
    },

    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
    },
    async doFetchNextPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      if (rows.length) {
        const currentPage = getters.currentPage || 1
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const lastDocument = rows[rows.length - 1]
        const pagination = {
          ...previousPagination,
          action: 'next',
          page: currentPage
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage + 1)
        await dispatch('doFetch', { pagination })
      }
    },
    async doFetchPreviousPage({ commit, getters, dispatch }) {
      const rows = getters.rows || []
      const currentPage = getters.currentPage || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters.pagination || INITIAL_PAGINATION_INPUT
        const firstDocument = rows[0]
        const pagination = {
          ...previousPagination,
          action: 'prev',
          page: currentPage
        }
        commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage - 1)
        await dispatch('doFetch', { pagination })
      }
    },

    async doFetch(
      { commit, getters },
      { filter, pagination, orderBy, keepPagination = true } = {}
    ) {
      try {
        commit('FETCH_STARTED', { filter, pagination, keepPagination })
        // const PaginationInput = {
        //   ...pagination,
        //   limit: pagination?.limit || getters.pagination.limit,
        //   sortBy: pagination?.sortBy || getters.pagination.sortBy
        // }
        // const response = await SidebarService.list(
        //   getters.filter,
        //   PaginationInput,
        //   orderBy
        // )
        const response = await SidebarService.listAll()
        commit('FETCH_SUCCESS', {
          rows: response
          // count: response.count,
          // isFirstPage: response.pagination?.isFirstPage,
          // isLastPage: response.pagination?.isLastPage
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },

    async doRemove({ commit, dispatch }, id) {
      try {
        commit('REMOVE_STARTED')
        await SidebarService.remove(id)
        await dispatch('doFetch')
        commit('REMOVE_SUCCESS')
      } catch (error) {
        Errors.handle(error)
        commit('REMOVE_ERROR')
      }
    },
    async doArrangeSidebar({ commit }, sidebarIds) {
      try {
        await SidebarService.arrangeSidebar(sidebarIds)
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
