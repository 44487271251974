import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  sidebarCreate: {
    id: 'sidebarCreate',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileSidebarEditor
    ]
  },
  sidebarEdit: {
    id: 'sidebarEdit',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileSidebarEditor
    ]
  },
  sidebarDestroy: {
    id: 'sidebarDestroy',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileSidebarEditor
    ]
  },
  sidebarImport: {
    id: 'sidebarImport',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileSidebarEditor
    ]
  },
  sidebarRead: {
    id: 'sidebarRead',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.mobileSidebarEditor,
      roles.mobileSidebarViewer
    ]
  },
  sidebarUserAutocomplete: {
    id: 'sidebarUserAutocomplete',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.mobileSidebarEditor,
      roles.mobileSidebarViewer
    ]
  }
}

export default Permissions
