/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlWallet)

export class WalletService {
  static async find() {
    const response = await graphqlClient.query({
      query: gql`
        query {
          findOwnerWallet {
            id
            amount
            currency
            email
            createdAt
          }
        }
      `
    })

    return response.data.findOwnerWallet
  }

  static async refresh() {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation {
          refreshOwnerWallet {
            id
            amount
            email
            currency
            updatedAt
            updatedBy
          }
        }
      `
    })
    return response.data.refreshOwnerWallet
  }

  /**
   * Retrieve wallets with pagination
   * - listWallets(filter: [Filter]!, pagination: PaginationInput!): [Wallet]!
   * @param {Object[]} filter
   * @param {String} filter.key
   * @param {String} filter.value
   * @param {'less'|'lessEqual'|'greater'|'greaterEqual'|'equal'|'notEqual'|'startsWith'} filter.operator
   * @param {Object} pagination
   * @param {Boolean} pagination.firstPage
   * @param {Int} pagination.limit
   * @param {JSON} pagination.doc
   * @param {'next'|'prev'} pagination.action
   * @param {'asc'|'desc'} pagination.sortType
   * @param {String} pagination.orderBy
   * @returns {Promise<Wallet[]>}
   */
  static async list(filter, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query WALLET_LIST($filter: [Filter]!, $pagination: PaginationInput!) {
          listWallets(filter: $filter, pagination: $pagination) {
            userId
            balance
            actualBalance
            userName
            phoneNumber
            email

            createdBy
            createdAt
            updatedBy
            updatedAt
          }
        }
      `,

      variables: {
        filter,
        pagination
      }
    })

    return response.data.listWallets
  }
  //#endregion
}
