/* eslint-disable */ // import { routerAsync } from '@/app-module';
import { OrderService } from '@/store/orders/orders-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'

export default {
  namespaced: true,

  state: {
    record: null,
    loading: false,
    statusLoading: false,
    addresses: [],
    addressLoading: false
  },

  getters: {
    record: state => state.record,
    loading: state => !!state.loading,
    statusLoading: state => !!state.statusLoading,
    addresses: state => state.addresses || [],
    addressLoading: state => !!state.addressLoading
  },

  mutations: {
    CHANGE_STATUS_STARTED(state) {
      state.statusLoading = true
    },
    CHANGE_STATUS_SUCCESS(state) {
      state.statusLoading = false
    },
    CHANGE_STATUS_ERROR(state) {
      state.statusLoading = false
    },

    FIND_STARTED(state) {
      state.record = null
      state.loading = true
    },
    FIND_SUCCESS(state, payload) {
      // const { providers_name, provider_orders } = payload
      // payload.providers_name = []
      // payload.provider_orders = []
      state.record = payload
      // setTimeout(() => {
      //   state.record.providers_name = providers_name
      //   state.record.provider_orders = provider_orders
      // }, 3000)
      state.loading = false
    },
    FIND_ERROR(state) {
      state.record = null
      state.loading = false
    },

    FIND_ADDRESSES_STARTED(state) {
      state.addresses = []
      state.addressLoading = true
    },
    FIND_ADDRESSES_SUCCESS(state, payload) {
      state.addresses = payload
      state.addressLoading = false
    },
    FIND_ADDRESSES_ERROR(state) {
      state.addresses = []
      state.addressLoading = false
    },

    RESEND_STARTED(state) {
      state.loading = true
    },
    RESEND_SUCCESS(state, payload) {
      state.record = payload
      state.loading = false
    },
    RESEND_ERROR(state) {
      state.loading = false
    }
  },

  actions: {
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await OrderService.find(parseFloat(id))
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/orders')
      }
    },
    async doResend({ commit }, { id }) {
      try {
        commit('RESEND_STARTED')
        const response = await OrderService.resend(id)
        if (response.status === true) {
          Message.success(i18n('orders.resendCodeSuccess'))
          commit('RESEND_SUCCESS', response.result)
        }
        if (response.status === false) {
          commit('RESEND_ERROR')
        }
      } catch (error) {
        Errors.handle(error)
        commit('RESEND_ERROR')
      }
    }
  }
}
