import { AuctionService } from './auction-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    findLoading: false,
    bidsLoading: false,
    itemsLoading: false,
    saveLoading: false,
    auctionRecord: null,
    bidsRecord: null,
    itemsRecord: null
  },
  getters: {
    auctionRecord: state => state.auctionRecord,
    bidsRecord: state => state.bidsRecord,
    itemsRecord: state => state.itemsRecord,
    bidsLoading: state => state.bidsLoading,
    itemsLoading: state => state.itemsLoading,
    findLoading: state => state.findLoading,
    saveLoading: state => state.saveLoading
  },
  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.bidsLoading = false
      state.itemsLoading = false
      state.auctionRecord = null
      state.bidsRecord = null
      state.itemsRecord = null
    },

    FIND_STARTED(state) {
      state.auctionRecord = null
      state.findLoading = true
    },

    FIND_SUCCESS(state, payload) {
      state.auctionRecord = payload
      state.findLoading = false
    },

    FIND_ERROR(state) {
      state.auctionRecord = null
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },
  actions: {
    doNew({ commit }) {
      commit('RESET')
    },
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const record = await AuctionService.findAuction(id)
        commit('FIND_SUCCESS', record)
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/auction')
      }
    },
    async doCreate({ commit }, values) {
      try {
        commit('ADD_STARTED')
        await AuctionService.createAuction(values)
        commit('ADD_SUCCESS')
        Message.success(i18n('auction.create.success'))
        router.push('/auction')
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },
    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED')
        await AuctionService.updateAuction(id, values)
        commit('UPDATE_SUCCESS')
        Message.success(i18n('auction.update.success'))
        router.push('/auction')
      } catch (error) {
        Errors.handle(error)
        commit('UPDATE_ERROR')
      }
    }
  }
}
