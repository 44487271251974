/* eslint-disable */ 
import sliderListStore from '@/store/slider/slider-list-store';
import sliderFormStore from '@/store/slider/slider-form-store';
import sliderViewStore from '@/store/slider/slider-view-store';
import sliderDestroyStore from '@/store/slider/slider-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: sliderListStore,
    form: sliderFormStore,
    view: sliderViewStore,
    destroy: sliderDestroyStore
  },
};
