/* eslint-disable */
import config from '@/config'
import GraphqlClient from '@/shared/graphql/client'
import gql from 'graphql-tag'

const graphqlClient = new GraphqlClient.initApolloClient(
  config.backendUrlAuction
)

const auction = ` {
        id
        auctionNo
        currency
        minimumBid
        minTargetPrice
        maxTargetPrice
        minBidIncrement
        maxBidIncrement
        auctionDuration
        durationUnit
        startDate
        endDate
        status
        reject_reason
        cancel_reason
        currentBid
        numOfBids
        highestBidder {
          fullName
        }
        seller
        bids {
          amount
          currency
          bidder {
              fullName
          }
        }
        items {
          id 
          title
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
    }`

const bid = `
{
    id
    amount
    currency
    bidder {
        fullName
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
}
`

const auctionItem = `
{
    id
    title
    description
    vendor
    product_type
    barcode
    sku
    # price
    # currency
    thumbnail
    images

    createdAt
    createdBy
    updatedAt
    updatedBy
}
`
export class AuctionService {
  static async acceptAuction(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ACCEPT($id: String!) {
          auctionAccept(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.auctionAccept
  }

  static async rejectAuction(id, reason) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REJECT($id: String!, $reason: String) {
          auctionReject(id: $id, reason: $reason)
        }
      `,
      variables: {
        id,
        reason
      }
    })
    return response.data.auctionReject
  }

  static async createAuction(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE($data: AuctionOwnerInput!) {
          auctionOwnerCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data
      }
    })
    return response.data.auctionOwnerCreate
  }

  static async updateAuction(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation UPDATE($id: String!, $data: AuctionOwnerInput!) {
          auctionOwnerUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })
    return response.data.auctionOwnerUpdate
  }

  static async findAuction(id) {
    const response = await graphqlClient.query({
      query: gql`
        query FIND($id: String!) {
            auctionFind(id: $id) ${auction}
        }
      `,
      variables: {
        id
      }
    })
    return response.data.auctionFind
  }

  static async listAuctions(filter = [], pagination, orderBy = 'createdAt') {
    const response = await graphqlClient.query({
      query: gql`
        query LIST(
    $filter: [ FilterInput! ], 
    $orderBy: String,
    $pagination: PaginationInput
) {
    auctionList(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
        pagination {
            isFirstPage
            isLastPage
        }
        count
        rows ${auction}
            }
          }
        `,
      variables: {
        orderBy,
        filter,
        pagination
      }
    })

    return response.data.auctionList
  }

  static async listAuctionBids(
    auctionId,
    filter = {},
    orderBy = 'createdAt',
    pagination
  ) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST(
    $auctionId: String!,
    $filter: [ FilterInput! ], 
    $orderBy: String,
    $pagination: PaginationInput
) {
    auctionBidList(auctionId: $auctionId, filter: $filter, orderBy: $orderBy, pagination: $pagination) {
        pagination {
            isFirstPage
            isLastPage
        }
        count
        rows ${bid}
            }
          }
        `,
      variables: {
        auctionId,
        orderBy,
        filter: Object.keys(filter).length === 0 ? null : filter,
        pagination
      }
    })

    return response.data.auctionBidList
  }

  static async listAuctionItems(auctionId) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST(
    $auctionId: String!
) {
    auctionItemList(auctionId: $auctionId) ${auctionItem}
          }
        `,
      variables: {
        auctionId
      }
    })

    return response.data.auctionItemList
  }

  static async addItemsToAuction(auctionId, itemIds) {
    // console.warn(auctionId, itemIds)
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation ADD($auctionId: String!, $itemIds: [String!]) {
          auctionOwnerAddItems(auctionId: $auctionId, itemIds: $itemIds)
        }
      `,
      variables: {
        auctionId,
        itemIds
      }
    })
    return response.data.auctionOwnerAddItems
  }

  static async removeItemsFromAuction(auctionId, itemIds) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation REMOVE($auctionId: String!, $itemIds: [String!]) {
          auctionOwnerRemoveItems(auctionId: $auctionId, itemIds: $itemIds)
        }
      `,
      variables: {
        auctionId,
        itemIds
      }
    })
    return response.data.auctionOwnerRemoveItems
  }
}
