import {
  RecaptchaVerifier,
  getAuth,
  linkWithPhoneNumber,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  unlink
} from 'firebase/auth'
import Message from '@/shared/message/toastify'
import { i18n } from '@/locales/i18n'

export default {
  namespaced: true,

  state: {
    loading: false,
    clientCode: null,
    recaptchaVerifier: null,
    recaptchaWidgetId: null,
    confirmationResult: null,
    resendTimer: 0,
    OTPSended: false,
    processing: false
  },

  getters: {
    userLang(rootState) {
      return rootState.layout.locale
    },
    phoneNumber(rootState) {
      return rootState.auth.currentUser.phoneNumber
    },
    timer(state) {
      return state.resendTimer
    },
    recaptchaVerifier(state) {
      return state.recaptchaVerifier
    },
    processing(state) {
      return state.processing
    },
    OTPSended(state) {
      return state.OTPSended
    }
  },

  mutations: {
    SET_VERIFICATION_CODE(state, recaptchaVerifier) {
      state.recaptchaVerifier = recaptchaVerifier
    },

    SET_RECAPTCHA_WIDGET_ID(state, recaptchaWidgetId) {
      state.recaptchaWidgetId = recaptchaWidgetId
    },

    SET_CONFIRMATION_RESULT(state, confirmationResult) {
      state.confirmationResult = confirmationResult
    },

    SET_PHONE_NUMBER(state, phoneNumber) {
      state.phoneNumber = phoneNumber
    },

    CODE_SEND_START(state) {
      state.verificationLoading = true
      state.processing = true
    },
    CODE_SEND_SUCCESS(state) {
      state.verificationLoading = false
      state.processing = false
    },
    CODE_SEND_ERROR(state) {
      state.verificationLoading = false
      state.processing = false
    },

    VERIFICATION_CODE_START(state) {
      state.verificationLoading = true
      state.processing = true
    },

    VERIFICATION_CODE_SUCCESS(state) {
      state.verificationLoading = false
    },

    SET_OPT_SENDED(state) {
      state.OTPSended = true
    },

    CLEAR_OPT_SENDED(state) {
      state.OTPSended = false
    },
    CLEAR_PROCESSING(state) {
      state.OTPSended = false
    }

    // VERIFICATION_CODE_ERROR(state) {
    //   state.verificationLoading = false
    // }
  },
  actions: {
    async sendOTP({ commit, state }, { phoneNumber, locale }) {
      const auth = getAuth()
      auth.languageCode = locale
      try {
        // Fake Verification
        commit('CODE_SEND_START')
        if (!state.recaptchaVerifier) {
          const newRecaptcha = new RecaptchaVerifier(
            'reCAPTCHA',
            {
              size: 'invisible'
            },
            auth
          )
          commit('SET_VERIFICATION_CODE', newRecaptcha)
        }
        state.recaptchaVerifier.render().then(async widgetId => {
          commit('SET_RECAPTCHA_WIDGET_ID', widgetId)
          const appVerifier = state.recaptchaVerifier

          linkWithPhoneNumber(auth.currentUser, phoneNumber, appVerifier)
            .then(confirmationResult => {
              commit('SET_CONFIRMATION_RESULT', confirmationResult)
              commit('SET_PHONE_NUMBER', phoneNumber)
              commit('SET_OPT_SENDED')
              commit('CODE_SEND_SUCCESS')
            })
            .catch(async error => {
              if (error.code === 'auth/provider-already-linked') {
                if (
                  auth.currentUser.phoneNumber !==
                  phoneNumber.replaceAll(' ', '')
                ) {
                  await unlink(auth.currentUser, PhoneAuthProvider.PROVIDER_ID)
                }

                return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                  .then(confirmationResult => {
                    commit('SET_CONFIRMATION_RESULT', confirmationResult)
                    commit('SET_PHONE_NUMBER', phoneNumber)
                    commit('SET_OPT_SENDED')
                    commit('CODE_SEND_SUCCESS')
                  })
                  .catch(error => {
                    Message.error(error.message)
                    commit('CODE_SEND_ERROR')
                    return false
                  })
              } else {
                Message.error(error.message)
                commit('CODE_SEND_ERROR')
                return false
              }
            })

          // Fake Sign in With phone to send code
          return true
        })
      } catch (error) {
        commit('CODE_SEND_ERROR')
        Message.error(error.message)
        localStorage.setItem('lastPhoneVerification', new Date())
        return false
      }
    },

    async link({ commit, state, dispatch }, { code, orderId, locale }) {
      try {
        await state.confirmationResult.confirm(code)

        commit('VERIFICATION_CODE_SUCCESS')
        // Message.success(i18n('verify.success'))
        localStorage.setItem('lastOTP', new Date().getTime())
        await dispatch('orders/view/doResend', { id: orderId }, { root: true })
        state.recaptchaVerifier.clear()
        return true
      } catch (error) {
        if (error.code === 'auth/invalid-verification-code') {
          Message.error(i18n(`verify.error`))
        } else if (
          error.code === 'auth/account-exists-with-different-credential'
        ) {
          commit('VERIFICATION_CODE_SUCCESS')
          // Message.success(i18n('verify.success'))
        } else {
          Message.error(i18n(`${error.message}`))
        }
        commit('VERIFICATION_CODE_SUCCESS')
        return false
      }
    },

    clearOTPSended({ commit }) {
      commit('CLEAR_OPT_SENDED')
    },

    clearProcessing({ commit }) {
      commit('CLEAR_PROCESSING')
    }
  }
}
