/* eslint-disable */
import { OrderService } from '@/store/orders/orders-service'
import Errors from '@/shared/error/errors'
import Message from '@/shared/message/toastify'
import { i18n } from '@/locales/i18n'


export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
  },

  mutations: {
    DESTROY_STARTED(state) {
      state.loading = true;
    },
    DESTROY_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ERROR(state) {
      state.loading = false;
    },

    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },
    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async doDestroy({ commit, dispatch, rootGetters }, id) {
      try {
        commit('DESTROY_STARTED');

        await OrderService.destroy(id)

        commit('DESTROY_SUCCESS');
        Message.success(i18n('order.destroy.success'))
        
        dispatch(`order/list/doFetch`, {
          filter: rootGetters[`user/list/filter`], 
          pagination: rootGetters[`user/list/pagination`]
        }, { root: true });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    }
  },
};
