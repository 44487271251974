import { AuctionService } from './auction-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'
import router from '@/router'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore
} from 'firebase/firestore'

export default {
  namespaced: true,
  state: {
    findLoading: false,
    saveLoading: false,
    auctionRecord: null,
    itemsRecord: null,
    bidsRecord: null
  },
  getters: {
    auctionRecord: state => state.auctionRecord,
    itemsRecord: state => state.itemsRecord,
    bidsRecord: state => state.bidsRecord,
    findLoading: state => state.findLoading,
    saveLoading: state => state.saveLoading
  },
  mutations: {
    RESET(state) {
      state.findLoading = false
      state.saveLoading = false
      state.auctionRecord = null
      state.itemsRecord = null
      state.bidsRecord = null
    },

    FIND_STARTED(state) {
      state.auctionRecord = null
      state.bidsRecord = null
      state.itemsRecord = null
      state.findLoading = true
    },

    FIND_AUCTION(state, payload) {
      state.auctionRecord = payload
    },
    FIND_SUCCESS(state) {
      state.findLoading = false
    },
    FIND_ITEMS(state, payload) {
      state.itemsRecord = payload
    },
    FIND_BIDS(state, payload) {
      state.bidsRecord = payload
    },
    FIND_ERROR(state) {
      state.itemsRecord = null
      state.bidsRecord = null
      state.auctionRecord = null
      state.findLoading = false
    },

    ADD_STARTED(state) {
      state.saveLoading = true
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false
    },

    ADD_ERROR(state) {
      state.saveLoading = false
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false
    }
  },
  actions: {
    doNew({ commit }) {
      commit('RESET')
    },
    async doFindWithListener({ commit }, id) {
      try {
        commit('FIND_STARTED')
        const DB = getFirestore()
        const auctionRef = doc(DB, 'auction', id)
        const docSnapshot = await getDoc(auctionRef)
        if (docSnapshot.exists()) {
          const auctionData = docSnapshot.data()
          commit('FIND_AUCTION', auctionData)
        }
        const bidsRef = collection(auctionRef, 'bids')
        const bidsSnapshot = await getDocs(bidsRef)
        const bids = []
        bidsSnapshot.forEach(doc => {
          bids.push(doc.data())
        })
        commit('FIND_BIDS', bids)
        const response = await AuctionService.listAuctionItems(id)
        commit('FIND_ITEMS', response)
        commit('FIND_SUCCESS')
      } catch (error) {
        Errors.handle(error)
        commit('FIND_ERROR')
        router.push('/auction')
      }
    },
    async doAddItems({ commit }, { auctionId, itemsIds }) {
      try {
        commit('ADD_STARTED')
        await AuctionService.addItemsToAuction(auctionId, itemsIds)
        const response = await AuctionService.listAuctionItems(auctionId)
        commit('FIND_ITEMS', response)
        commit('ADD_SUCCESS')
        Message.success(i18n('auction.createItems.success'))
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    },
    async doDeleteItem({ commit }, { auctionId, itemsIds }) {
      try {
        commit('ADD_STARTED')
        await AuctionService.removeItemsFromAuction(auctionId, itemsIds)
        const response = await AuctionService.listAuctionItems(auctionId)
        commit('FIND_ITEMS', response)
        commit('ADD_SUCCESS')
        Message.success(i18n('auction.deleteItems.success'))
      } catch (error) {
        Errors.handle(error)
        commit('ADD_ERROR')
      }
    }
  }
}
