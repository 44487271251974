/* eslint-disable */
import settingGeneralStore from '@/store/setting/setting-general-store'
import settingPolicyStore from '@/store/setting/setting-policy-store'
import settingGateWaysStore from '@/store/setting/setting-gateway-store'
import settingMembershipsStore from '@/store/setting/setting-membership-store'
import settingTermsStore from './setting-terms-store'

export default {
  namespaced: true,
  modules: {
    general: settingGeneralStore,
    policy: settingPolicyStore,
    terms: settingTermsStore,
    gateWays: settingGateWaysStore,
    memberships: settingMembershipsStore
  }
}
