import iamPermissions from './permissions/iamPermissions'
import userPermissions from './permissions/userPermissions'
import categoryPermissions from './permissions/categoryPermissions'
import productPermissions from './permissions/productPermissions'
import walletPermissions from './permissions/walletPermissions'
import orderPermissions from './permissions/orderPermissions'
import auctionPermissions from './permissions/auctionPermissions'
import sidebarPermissions from './permissions/sidebarPermissions'
import sliderPermissions from './permissions/sliderPermissions'
import notificationPermissions from './permissions/notificationPermissions'
import settingsPermissions from './permissions/settingsPermissions'
import portalPermissions from './permissions/portalPermissions'

const Permissions = {
  ...iamPermissions,
  ...userPermissions,
  ...categoryPermissions,
  ...productPermissions,
  ...walletPermissions,
  ...orderPermissions,
  ...auctionPermissions,
  ...sidebarPermissions,
  ...sliderPermissions,
  ...notificationPermissions,
  ...settingsPermissions,
  ...portalPermissions
}

export default Permissions
