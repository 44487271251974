/* eslint-disable */ 
import ordersListStore from '@/store/orders/orders-list-store';
import ordersViewStore from '@/store/orders/orders-view-store';
import orderDestroyStore from '@/store/orders/order-destroy-store';

export default {
  namespaced: true,

  modules: {
    list: ordersListStore,
    view: ordersViewStore,
    destroy: orderDestroyStore,
  },
};
