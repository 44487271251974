/* eslint-disable */
import { SettingService } from '@/store/setting/setting-service'
import Message from '@/shared/message/toastify'
import Errors from '@/shared/error/errors'
import { i18n } from '@/locales/i18n'

export default {
  namespaced: true,

  state: {
    rows: [],
    loading: false
  },

  getters: {
    rows: state => state.rows || [],
    loading: state => state.loading
  },

  mutations: {
    FETCH_STARTED(state) {
      state.loading = true
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
    },

    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
    },

    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true
    },

    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false
    },

    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      state.loading = false
    }
  },

  actions: {
    async doFetch({ commit }) {
      try {
        commit('FETCH_STARTED')
        const response = await SettingService.listGateways()
        commit('FETCH_SUCCESS', {
          rows: response
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },
    async doToggleGatewaysStatus(
      { commit, dispatch },
      { gateway_id, payments }
    ) {
      try {
        commit('CHANGE_STATUS_SELECTED_STARTED')
        await SettingService.toggleStatus(gateway_id, payments)
        commit('CHANGE_STATUS_SELECTED_SUCCESS')
        Message.success(i18n('settings.appliedSuccessfully'))

        dispatch('doFetch')
      } catch (error) {
        Errors.handle(error)
        commit('CHANGE_STATUS_SELECTED_ERROR')
        dispatch('doFetch')
      }
    }
  }
}
