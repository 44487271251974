/* eslint-disable */

// import { firestoreAction } from 'vuexfire'
// import FirebaseRepository from '@/shared/firebase/firebase-repository'
import firebase from 'firebase/compat/app'
// import 'firebase/compat/firestore'
import 'firebase/compat/database'
import { getDatabase, ref, onValue } from "firebase/database"
// import { collection, doc, query, where, orderBy, onSnapshot } from 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    ordersBadge: 0,
    shippingOrdersBadge: 0,
    returnOrdersBadge: 0,
    productsBadge: 0,
    requestsBadge: 0,
    loading: false
  },

  getters: {
    ordersBadge: (state) => state.ordersBadge || 0,
    shippingOrdersBadge: (state) => state.shippingOrdersBadge || 0,
    returnOrdersBadge: (state) => state.returnOrdersBadge || 0,
    productsBadge: (state) => state.productsBadge || 0,
    requestsBadge: (state) => state.requestsBadge || 0,
    loading: (state) => !!state.loading,
  },
  mutations: {
    LISTENER_STARTED(state) {
      state.loading = true
    },
    LISTENER_SUCCESS(state) {
      state.loading = false
    },

    ORDER_LISTENER_SUCCESS(state, payload) {
      state.ordersBadge = payload
      state.loading = false
    },
    SHIPPING_ORDER_LISTENER_SUCCESS(state, payload) {
      state.shippingOrdersBadge = payload
      state.loading = false
    },
    RETURN_ORDER_LISTENER_SUCCESS(state, payload) {
      state.returnOrdersBadge = payload
      state.loading = false
    },
    PRODUCT_LISTENER_SUCCESS(state, payload) {
      state.productsBadge = payload
      state.loading = false
    },
    REQUEST_LISTENER_SUCCESS(state, payload) {
      state.requestsBadge = payload
      state.loading = false
    },
  },
  actions: {
    async doRunListenerOnNewOrders({ commit }) {
      commit('LISTENER_STARTED')
      const db = getDatabase()
      
      try {
        const statsRef = ref(db, 'ordersBadge')
        onValue(statsRef, (snapshot) => {
          const data = snapshot.val()
          const value = data && data.value ? data.value : 0
          commit('ORDER_LISTENER_SUCCESS', value)
        })
      } catch (error) {
        console.error('ordersBadge error =', error)
        commit('ORDER_LISTENER_SUCCESS', 0)
      }

      try {
        const shippingOrderRef = ref(db, 'shippingOrdersBadge')
        onValue(shippingOrderRef, (snapshot) => {
          const data = snapshot.val()
          const value = data && data.value ? data.value : 0
          commit('SHIPPING_ORDER_LISTENER_SUCCESS', value)
        })
      } catch (error) {
        console.error('shippingOrdersBadge error =', error)
        commit('SHIPPING_ORDER_LISTENER_SUCCESS', 0)
      }

      try {
        const returnOrderRef = ref(db, 'returnOrdersBadge')
        onValue(returnOrderRef, (snapshot) => {
          const data = snapshot.val()
          const value = data && data.value ? data.value : 0
          commit('RETURN_ORDER_LISTENER_SUCCESS', value)
        })
      } catch (error) {
        console.error('returnOrdersBadge error =', error)
        commit('RETURN_ORDER_LISTENER_SUCCESS', 0)
      }
    },
    async doRunListenerOnNewProducts({ commit }) {
      try {
        commit('LISTENER_STARTED')
        
        const db = getDatabase()
        const statsRef = ref(db, 'productsBadge')
        onValue(statsRef, (snapshot) => {
          const data = snapshot.val()
          const value = data && data.value ? data.value : 0
          commit('PRODUCT_LISTENER_SUCCESS', value)
        })
      } catch (error) {
        console.error('productsBadge error =', error)
        commit('PRODUCT_LISTENER_SUCCESS', 0)
      }
    },
    async doRunListenerOnNewRequests({ commit }) {
      try {
        commit('LISTENER_STARTED')
        
        const db = getDatabase()
        const statsRef = ref(db, 'requestsBadge')
        onValue(statsRef, (snapshot) => {
          const data = snapshot.val()
          const value = data && data.value ? data.value : 0
          commit('REQUEST_LISTENER_SUCCESS', value)
        })
      } catch (error) {
        console.error('requestsBadge error =', error)
        commit('REQUEST_LISTENER_SUCCESS', 0)
      }
    },
  }
}
