import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  auctionCreate: {
    id: 'auctionCreate',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileAuctionEditor
    ]
  },
  auctionEdit: {
    id: 'auctionEdit',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileAuctionEditor
    ]
  },
  auctionDestroy: {
    id: 'auctionDestroy',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileAuctionEditor
    ]
  },
  auctionImport: {
    id: 'auctionImport',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.mobileAuctionEditor
    ]
  },
  auctionRead: {
    id: 'auctionRead',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.mobileAuctionEditor,
      roles.mobileAuctionViewer
    ]
  },
  auctionUserAutocomplete: {
    id: 'auctionUserAutocomplete',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.mobileAuctionEditor,
      roles.mobileAuctionViewer
    ]
  }
}

export default Permissions
