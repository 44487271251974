/* eslint-disable */     

import { CategoryService } from '@/store/category/category-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
// import { routerAsync } from '@/app-module';
import router from '@/router'
// import FirebaseRepository from '@/shared/firebase/firebase-repository'
import { FileUploader } from '@/shared/uploader/file-uploader'

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    ADD_STARTED(state) {
      state.saveLoading = true;
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false;
    },

    ADD_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await CategoryService.findCategoryById(id);
        // const record = await FirebaseRepository.findDocument('categories', id)
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/category');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('ADD_STARTED');
        await CategoryService.createCategory(values);
        commit('ADD_SUCCESS');
        Message.success(i18n('category.create.success'));
        router.push('/category');
      } catch (error) {
        Errors.handle(error);
        commit('ADD_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await CategoryService.editCategory(id, values);
        commit('UPDATE_SUCCESS');

        Message.success(i18n('category.update.success'));
        router.push('/category');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doSetCategoryImage({ commit, dispatch }, { categoryId, imageFile, route = false}) {
      try {
        commit('UPDATE_STARTED');
        // [1] Upload the image of category to storage of firebase
        const path = `category/${categoryId}`
        const { publicUrl } = imageFile ? await FileUploader.upload(path, imageFile) : { publicUrl: null }

        // [2] Add image to category of firestore
        await CategoryService.setCategoryImage(categoryId, publicUrl);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('category.update.success'));

        if (route) {
          router.push(`/category`);
        } else {
          dispatch(`category/list/doFetch`, {}, { root: true });
        }
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doArrangeCategories({ commit }, categoriesIds) {
      try {
        await CategoryService.arrangeCategories(categoriesIds);
        // dispatch(`category/list/doFetch`, {}, { root: true });
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
