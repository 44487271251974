import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  productEdit: {
    id: 'productEdit',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.providerProductEditor
    ]
  },
  productRead: {
    id: 'productRead',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.providerProductEditor,
      roles.providerProductViewer
    ]
  }
}

export default Permissions
