import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  walletRead: {
    id: 'walletRead',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.providerWalletViewer
    ]
  }
}

export default Permissions
