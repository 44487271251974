import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  settingsRead: {
    id: 'settingsRead',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.settingsEditor,
      roles.settingsViewer
    ]
  },
  settingsEdit: {
    id: 'settingsEdit',
    allowedRoles: [roles.owner, roles.admin, roles.editor, roles.settingsEditor]
  }
}

export default Permissions
