/* eslint-disable */
import { AuctionService } from '@/store/auction/auction-service'
import Errors from '@/shared/error/errors'

const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGINATION_INPUT = {
  limit: INITIAL_PAGE_SIZE,
  sortBy: 'desc'
}

const AuctionObj = {
  rowsType: 'rows',
  paginationType: 'pagination',
  currentPageType: 'currentPage',
  fetch: 'doFetch',
  commitStr: 'PAGINATION_CURRENT_PAGE_CHANGED'
}

export default {
  namespaced: true,

  state: {
    rows: [],
    bidsRows: [],
    itemsRows: [],
    count: 0,
    bidsCount: 0,
    itemsCount: 0,
    bidsLoading: false,
    itemsLoading: false,
    loading: false,
    isFirstPage: null,
    isBidsFirstPage: null,
    isItemsFirstPage: null,
    isBidsLastPage: null,
    isLastPage: null,
    isItemsLastPage: null,
    filter: [],
    currentPage: 1,
    bidsCurrentPage: 1,
    itemsCurrentPage: 1,
    pagination: INITIAL_PAGINATION_INPUT,
    itemsPagination: INITIAL_PAGINATION_INPUT,
    bidsPagination: INITIAL_PAGINATION_INPUT
  },

  getters: {
    loading: state => state.loading,
    bidsLoading: state => state.bidsLoading,
    itemsLoading: state => state.itemsLoading,
    rows: state => state.rows || [],
    bidsRows: state => state.bidsRows || [],
    itemsRows: state => state.itemsRows || [],
    itemsCount: state => state.itemsCount,
    bidsCount: state => state.bidsCount,
    count: state => state.count,
    isFirstPage: state => state.isFirstPage,
    isBidsFirstPage: state => state.isBidsFirstPage,
    isItemsFirstPage: state => state.isItemsFirstPage,
    isLastPage: state => state.isLastPage,
    isBidsLastPage: state => state.isBidsLastPage,
    isItemsLastPage: state => state.isItemsLastPage,
    hasRows: (state, getters) => getters.count > 0,
    filter: state => state.filter,
    currentPage: state => state.currentPage,
    bidsCurrentPage: state => state.bidsCurrentPage,
    itemsCurrentPage: state => state.itemsCurrentPage,
    pagination: state => state.pagination,
    itemsPagination: state => state.itemsPagination,
    bidsPagination: state => state.bidsPagination,
    limit: state => {
      const pagination = state.pagination

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE
      }
      return pagination.pageSize
    }
  },

  mutations: {
    RESETED(state) {
      state.rows = []
      state.itemsRows = []
      state.bidsRows = []
      state.count = 0
      state.bidsCount = 0
      state.itemsCount = 0
      state.bidsLoading = false
      state.itemsLoading = false
      state.loading = false
      state.filter = []
      state.pagination = INITIAL_PAGINATION_INPUT
      state.bidsPagination = INITIAL_PAGINATION_INPUT
      state.itemsPagination = INITIAL_PAGINATION_INPUT
      state.sorter = {}
      state.currentPage = 1
      state.bidsCurrentPage = 1
      state.itemsCurrentPage = 1
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || INITIAL_PAGINATION_INPUT
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage =
        payload && payload > 0 ? payload : state.currentPage || 1
    },
    PAGINATION_BIDS_CURRENT_PAGE_CHANGED(state, payload) {
      state.bidsCurrentPage =
        payload && payload > 0 ? payload : state.bidsCurrentPage || 1
    },
    PAGINATION_ITEMS_CURRENT_PAGE_CHANGED(state, payload) {
      state.itemsCurrentPage =
        payload && payload > 0 ? payload : state.itemsCurrentPage || 1
    },
    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || INITIAL_PAGINATION_INPUT
      state.currentPage = state.currentPage || 1
      state.pagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },
    PAGINATION_BIDS_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination =
        state.bidsPagination || INITIAL_PAGINATION_INPUT
      state.bidsCurrentPage = state.bidsCurrentPage || 1
      state.bidsPagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },
    PAGINATION_ITEMS_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination =
        state.itemsPagination || INITIAL_PAGINATION_INPUT
      state.itemsCurrentPage = state.itemsCurrentPage || 1
      state.itemsPagination = {
        ...previousPagination,
        limit: payload || INITIAL_PAGE_SIZE
      }
    },
    FETCH_STARTED(state, payload) {
      state.loading = true
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.pagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.pagination
          : {}
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false
      state.rows = payload.rows
      state.count = payload.count
      state.isFirstPage = payload.isFirstPage
      state.isLastPage = payload.isLastPage
    },
    FETCH_ERROR(state) {
      state.loading = false
      state.rows = []
      state.count = 0
    },
    FETCH_ITEMS_STARTED(state, payload) {
      state.itemsLoading = true
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.itemsPagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.itemsPagination
          : {}
    },
    FETCH_ITEMS_SUCCESS(state, payload) {
      state.itemsLoading = false
      state.itemsRows = payload.rows
      state.itemsCount = payload.count
      state.isItemsFirstPage = payload.isItemsFirstPage
      state.isItemsLastPage = payload.isItemsLastPage
    },
    FETCH_ITEMS_ERROR(state) {
      state.itemsLoading = false
      state.itemsRows = []
      state.itemsCount = 0
    },
    FETCH_BIDS_STARTED(state, payload) {
      state.bidsLoading = true
      state.filter = payload && payload.filter ? payload.filter : state.filter
      state.bidsPagination =
        payload && payload.pagination
          ? payload.pagination
          : payload.keepPagination
          ? state.bidsPagination
          : {}
    },
    FETCH_BIDS_SUCCESS(state, payload) {
      state.bidsLoading = false
      state.bidsRows = payload.rows
      state.bidsCount = payload.count
      state.isBidsFirstPage = payload.isBidsFirstPage
      state.isBidsLastPage = payload.isBidsLastPage
    },
    FETCH_BIDS_ERROR(state) {
      state.bidsLoading = false
      state.bidsRows = []
      state.bidsCount = 0
    },
    REMOVE_STARTED(state) {
      state.loading = true
    },
    REMOVE_SUCCESS(state) {
      state.loading = false
    },
    REMOVE_ERROR(state) {
      state.loading = false
    },
    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true
    },
    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false
    },
    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      state.loading = false
    }
  },

  actions: {
    async doReset({ commit, dispatch }) {
      commit('RESETED')
      return dispatch('doFetch')
    },
    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.pagination
      dispatch('doFetch', { filter, pagination, keepPagination: true })
    },
    doChangeBidsPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_BIDS_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.bidsPagination
      dispatch('doFetchBids', { filter, pagination, keepPagination: true })
    },
    doChangeItemsPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_ITEMS_PAGE_SIZE_CHANGED', pageSize)
      const filter = getters.filter
      const pagination = getters.itemsPagination
      dispatch('doFetchItems', { filter, pagination, keepPagination: true })
    },
    doChangePaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage)
    },
    doChangeBidsPaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_BIDS_CURRENT_PAGE_CHANGED', currentPage)
    },
    doChangeItemsPaginationCurrentPage({ commit }, currentPage) {
      commit('PAGINATION_ITEMS_CURRENT_PAGE_CHANGED', currentPage)
    },
    async doFetchAction(_, { type, action }) {
      const itemsObj = {
        rowsType: 'itemsRows',
        paginationType: 'itemsPagination',
        currentPageType: 'itemsCurrentPage',
        fetch: 'doFetchItems',
        commitStr: 'PAGINATION_ITEMS_CURRENT_PAGE_CHANGED'
      }
      const bidsObj = {
        rowsType: 'bidsRows',
        paginationType: 'bidsPagination',
        currentPageType: 'bidsCurrentPage',
        fetch: 'doFetchBids',
        commitStr: 'PAGINATION_BIDS_CURRENT_PAGE_CHANGED'
      }

      if (action === 'prev') {
        type === 'bids'
          ? await this.doFetchPreviousPage(bidsObj)
          : await this.doFetchPreviousPage(itemsObj)
      }
      if (action === 'next') {
        type === 'bids'
          ? await this.doFetchNextPage(bidsObj)
          : await this.doFetchNextPage(itemsObj)
      }
    },
    async doFetchNextPage(
      { commit, getters, dispatch },
      {
        rowsType,
        paginationType,
        currentPageType,
        fetch,
        commitStr
      } = AuctionObj
    ) {
      const rows = getters[rowsType] || []
      if (rows.length) {
        const currentPage = getters[currentPageType] || 1
        const previousPagination =
          getters[paginationType] || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'next',
          page: currentPage
        }
        commit(commitStr, currentPage + 1)
        await dispatch(fetch, { pagination })
      }
    },
    async doFetchPreviousPage(
      { commit, getters, dispatch },
      {
        rowsType,
        paginationType,
        currentPageType,
        fetch,
        commitStr
      } = AuctionObj
    ) {
      const rows = getters[rowsType] || []
      const currentPage = getters[currentPageType] || 1
      if (currentPage > 1 && rows.length) {
        const previousPagination =
          getters[paginationType] || INITIAL_PAGINATION_INPUT
        const pagination = {
          ...previousPagination,
          action: 'prev',
          page: currentPage
        }
        commit(commitStr, currentPage - 1)
        await dispatch(fetch, { pagination })
      }
    },

    async doFetch(
      { commit, getters },
      { filter, pagination, orderBy, keepPagination = true } = {}
    ) {
      try {
        commit('FETCH_STARTED', { filter, pagination, keepPagination })
        const PaginationInput = {
          ...pagination,
          limit: pagination?.limit || getters.pagination.limit,
          sortBy: pagination?.sortBy || getters.pagination.sortBy
        }
        const response = await AuctionService.listAuctions(
          getters.filter,
          PaginationInput,
          orderBy
        )
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
          isFirstPage: response.pagination?.isFirstPage,
          isLastPage: response.pagination?.isLastPage
        })
      } catch (error) {
        Errors.handle(error)
        commit('FETCH_ERROR')
      }
    },

    async doRemove({ commit, dispatch }, id) {
      try {
        commit('REMOVE_STARTED')
        await AuctionService.remove(id)
        await dispatch('doFetch')
        commit('REMOVE_SUCCESS')
      } catch (error) {
        Errors.handle(error)
        commit('REMOVE_ERROR')
      }
    }
  }
}
