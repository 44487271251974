/* eslint-disable */ 
// import { routerAsync } from '@/app-module';
import { ProductService } from '@/store/product/product-service';
import Message from '@/shared/message/toastify';
import Errors from '@/shared/error/errors';
import { i18n } from '@/locales/i18n';
import router from '@/router';

// import lodash from 'lodash';
// import FirebaseRepository from '@/shared/firebase/firebase-repository';
// import { firestoreAction, vuexfireMutations } from 'vuexfire'
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'

export default {
  namespaced: true,

  state: {
    record: null,
    loading: false,
    shadowRecord: null,
    shadowLoading: false,
    destroyLoading: false
  },

  getters: {
    record: (state) => state.record,
    shadowRecord: (state) => state.shadowRecord,
    loading: (state) => !!state.loading,
    shadowLoading: (state) => !!state.shadowLoading,
    destroyLoading: (state) => !!state.destroyLoading,
  },

  mutations: {
    // ...vuexfireMutations, // adds Vuexfire built-in mutations
    // VUEXFIRE_FIND_SUCCESS(state) {
    //   state.loading = false;
    // },
    // VUEXFIRE_FIND_OWNER_SUCCESS(state, payload) {
    //   if (lodash.isArray(payload)) {
    //     state.record = payload.length ? payload[0] : null
    //   } else {
    //     state.record = payload
    //   }
    //   state.loading = false;
    // },

    CHANGE_STATUS_STARTED(state) {
      state.statusLoading = true;
    },

    CHANGE_STATUS_SUCCESS(state) {
      state.statusLoading = false;
    },

    CHANGE_STATUS_ERROR(state) {
      state.statusLoading = false;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },

    FIND_SHADOW_RECORD_STARTED(state) {
      state.shadowRecord = null;
      state.shadowLoading = true;
    },
    FIND_SHADOW_RECORD_SUCCESS(state, payload) {
      state.shadowRecord = payload;
      state.shadowLoading = false;
    },
    FIND_SHADOW_RECORD_ERROR(state) {
      state.shadowRecord = null;
      state.shadowLoading = false;
    },

    DESTROY_STARTED(state) {
      state.destroyLoading = true;
    },
    DESTROY_SUCCESS(state) {
      state.destroyLoading = false;
    },
    DESTROY_ERROR(state) {
      state.destroyLoading = false;
    },
  },

  actions: {
    // doFindByVuexfire: firestoreAction(async (context, id) => {
    //   try {
    //     context.commit('FIND_STARTED');

    //     const customSerializer = (doc) => {
    //       const data = FirebaseRepository.mapDocument(doc)
    //       Object.defineProperty(data, '_doc', { value: doc })
    //       return data
    //     }

    //     const db = firebase.firestore()
    //     const ProductQuery = db.collection('user').doc(id)
          
    //     await context.bindFirestoreRef(
    //       'record',
    //       ProductQuery,
    //       { serialize: customSerializer }
    //     )
        
    //     context.commit('VUEXFIRE_FIND_SUCCESS');
    //   } catch (error) {
    //     Errors.handle(error);
    //     context.commit('FIND_ERROR');
    //     router.push('/products');
    //   }
    // }),

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await ProductService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        router.push('/products');
      }
    },

    async doFindShadowRecord({ commit }, id) {
      try {
        commit('FIND_SHADOW_RECORD_STARTED');
        const record = await ProductService.find(id);
        commit('FIND_SHADOW_RECORD_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_SHADOW_RECORD_ERROR');
        router.push('/products');
      }
    },

    async doDestroy({ commit }, id) {
      try {
        commit('DESTROY_STARTED');

        await ProductService.destroy(id)

        commit('DESTROY_SUCCESS');
        Message.success(i18n('product.destroy.success'))
        router.push('/products')
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    }
    // async doToggleStatus({ getters, commit, dispatch }) {
    //   try {
    //     const record = getters.record;

    //     commit('CHANGE_STATUS_STARTED');

    //     const isEnabling = !!record.disabled;

    //     if (isEnabling) {
    //       await ProductService.enable([record.id]);
    //     } else {
    //       await ProductService.disable([record.id]);
    //     }

    //     if (isEnabling) {
    //       Message.success(i18n('user.doEnableSuccess'));
    //     } else {
    //       Message.success(i18n('user.doDisableSuccess'));
    //     }

    //     dispatch('doFind', record.id);
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('CHANGE_STATUS_ERROR');
    //   }
    // },

    // async doToggleProductAccountStatus(context, id) {
    //   try {
    //     // debugger
        
    //     const db = firebase.firestore();
    //     const doc = await db.collection('user').doc(id).get();
    //     const record = FirebaseRepository.mapDocument(doc)

    //     context.commit('CHANGE_STATUS_STARTED');

    //     const isEnabling = !!record.disabled;

    //     if (isEnabling) {
    //       await ProductService.enable([id]);
    //     } else {
    //       await ProductService.disable([id]);
    //     }

    //     if (isEnabling) {
    //       Message.success(i18n('user.doEnableSuccess'));
    //     } else {
    //       Message.success(i18n('user.doDisableSuccess'));
    //     }

    //     context.commit('CHANGE_STATUS_SUCCESS');
    //     // context.dispatch('doFind', record.id);
    //   } catch (error) {
    //     Errors.handle(error);
    //     context.commit('CHANGE_STATUS_ERROR');
    //   }
    // },
  },
};
