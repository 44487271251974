<template>
  <section>
    <div v-if="isMultiple">
      <div class="w-10 h-10 flex justify-center">
        <img
          v-for="(img, index) in value"
          :key="index"
          :src="img"
          class="rounded-full border-white border-1 cursor-pointer image"
          :style="`z-index: ${1000 - index};`"
          data-action="zoom"
        />
      </div>
    </div>
    <div class="font-medium whitespace-nowrap text-gray-600" v-else>
      <div
        class="w-10 h-10 flex-none image-fit rounded-md overflow-hidden rounded-full"
        style="border-radius: 50%;"
      >
        <img
          v-if="imgSrc && !isBlank"
          :src="imgSrc"
          class="rounded-full cursor-pointer"
          style="border-radius: 50%;"
          @mouseover="mouseOver"
          @mouseleave="mouseLeave"
          data-action="zoom"
        />
        <img
          src="/images/default-avatar.svg"
          class="rounded-full"
          style="border-radius: 50%;"
          v-else-if="type == 'user'"
        />
        <img
          :src="require(`@/assets/images/profile-1.jpg`)"
          class="rounded-full"
          v-else
        />
      </div>
    </div>
  </section>
</template>

<script>
import lodash from 'lodash'

export default {
  name: 'app-list-item-image',
  props: {
    value: {
      type: String || Array || Object
    },
    multiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    isBlank() {
      return !this.value || !this.value.length
    },
    imgSrc() {
      let src = ''
      if (lodash.isString(this.value)) {
        src = this.value
      } else if (lodash.isArray(this.value)) {
        src =
          this.value.length && this.value[0].publicUrl
            ? this.value[0].publicUrl
            : this.value[0] || null
      } else if (lodash.isObject(this.value)) {
        src = this.value ? this.value.publicUrl : null
      }
      return src
    },
    isMultiple() {
      return (
        this.multiple &&
        this.value &&
        lodash.isArray(this.value) &&
        this.value.length
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.zoom {
  transition: transform 0.2s; /* Animation */
}

.zoom:hover {
  transform: scale(
    1.5
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.GRID {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr) !important;
  width: 80% !important;
  max-width: 600px !important;
  margin: 0 auto !important;
}

.image:nth-child(n + 2) {
  // grid-column: 1 / 3 !important;
  margin-left: -50% !important;
}
</style>
