<template>
  <div class="dropdown w-1/2 sm:w-auto">
    <button class="dropdown-toggle btn btn-outline-secondary dark:text-white w-full sm:w-auto" aria-expanded="false">
      <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
      <app-i18n code="common.table.export"></app-i18n>
      <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2 sm:mr-2" />
    </button>
    <div class="dropdown-menu w-40">
      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
        <a @click="onDownloadPdf"
          id="tabulator-export-xlsx"
          href="javascript:;"
          class="flex items-center block p-2 rounded-md
                      transition duration-300 ease-in-out
                      bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
        >
          <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
          {{ i18n('common.table.exportOptions.exportToPDF') }}
        </a>
        <a @click="onExportXlsx"
          id="tabulator-export-html"
          href="javascript:;"
          class="flex items-center block p-2 rounded-md
                      transition duration-300 ease-in-out
                      bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
        >
          <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
          {{ i18n('common.table.exportOptions.exportToXLSX') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'action-export-button',

  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    onExportXlsx() {
      this.$emit('export-xlsx')
    },
    onDownloadPdf() {
      this.$emit('download-pdf')
    }
  }
})
</script>

<style scoped>

</style>
