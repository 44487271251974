/* eslint-disable */
import filesize from 'filesize'

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from 'firebase/storage'

function extractExtensionFrom(filename) {
  if (!filename) {
    return null
  }

  const regex = /(?:\.([^.]+))?$/
  return regex.exec(filename)[1]
}

export class FileUploader {
  static validate(file, schema) {
    if (!schema) {
      return
    }
    if (schema.image) {
      if (!file.type.startsWith('image')) {
        throw new Error('fileUploader.image')
      }
    }

    if (schema.size && file.size > schema.size) {
      throw new Error('fileUploader.size', filesize(schema.size))
    }

    const extension = extractExtensionFrom(file.name)

    if (schema.formats && !schema.formats.includes(extension)) {
      throw new Error('fileUploader.formats', schema.formats.join('/'))
    }
  }

  static uploadFromRequest(path, request, schema, imageName) {
    try {
      FileUploader.validate(request.file, schema)
    } catch (error) {
      request.onError(error)
      return
    }

    const ref = firebase.storage().ref()
    const extension = extractExtensionFrom(request.file.name)
    // const id = Math.floor((new Date()).getTime() / 1000);
    const fullPath = `${path}/${imageName}.${extension}`
    const task = ref.child(fullPath).put(request.file)

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

        setTimeout(() => {
          request.onProgress({ percent })
        }, 0)
      },
      error => {
        request.onError(error)
      },
      () => {
        task.snapshot.ref
          .getDownloadURL()
          .then(url => {
            request.onSuccess({
              id: imageName,
              name: request.file.name,
              sizeInBytes: task.snapshot.totalBytes,
              privateUrl: fullPath,
              publicUrl: url,
              new: true
            })
          })
          .catch(error => {
            request.onError(error)
          })
      }
    )
  }

  static async upload(path, request, name) {
    return new Promise((resolve, reject) => {
      let uploads
      const promises = []
      const file = request
      const extension = extractExtensionFrom(file.name)
      const fileName = name || file.name.replace(`.${extension}`, '')
      const fullPath = `${path}/${fileName}.${extension}`

      const storage = getStorage()
      const storageRef = ref(storage, fullPath)
      const uploadTask = uploadBytesResumable(storageRef, file)
      uploadTask.on(
        'state_changed',
        snapshot => {},
        error => {
          reject()
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
          uploads = {
            name: file.name,
            publicUrl: downloadURL
          }
          resolve(uploads)
        }
      )
    })
  }
  static async delete(path, file, name) {
    const extension = extractExtensionFrom(file.name)
    const fileName = name || file.name.replace(`.${extension}`, '')
    const fullPath = `${path}/${fileName}.${extension}`
    const storage = getStorage()
    const storageRef = ref(storage, fullPath)
    deleteObject(storageRef)
      .then(() => console.log('file deleted'))
      .catch(err => console.error(err))
  }
}
