import Roles from '../roles'

const roles = Roles.values

const Permissions = {
  orderEdit: {
    id: 'orderEdit',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.resendCodeAction
    ]
  },
  orderDestroy: {
    id: 'orderDestroy',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.editor,
      roles.resendCodeAction
    ]
  },
  orderRead: {
    id: 'orderRead',
    allowedRoles: [
      roles.owner,
      roles.admin,
      roles.viewer,
      roles.editor,
      roles.resendCodeAction,
      roles.providerOrderViewer
    ]
  }
}

export default Permissions
