/* eslint-disable */
import config from '@/config'
import gql from 'graphql-tag'
import GraphqlClient from '@/shared/graphql/client'
const graphqlClient = GraphqlClient.initApolloClient(config.backendUrlSidebar)

const sidebar = `
{
  id
  title {
    en
    ar
  }
  type
  imageIcon {
    name
    publicUrl
  }
  isActive
  itemId
  position
  createdAt
  updatedAt
  createdBy
  updatedBy
}`

export class SidebarService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CREATE($data: MenuItemInput!) {
          sidebarCreateMenuItem(data: $data) ${sidebar}
        }
      `,
      variables: {
        data
      }
    })
    return response.data.sidebarCreateMenuItem
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation UPDATE($id: String!, $data: MenuItemInput!) {
          sidebarUpdateMenuItem(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      }
    })

    return response.data.sidebarUpdateMenuItem
  }

  static async remove(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DESTROY($id: String!) {
          sidebarDestroyMenuItem(id: $id)
        }
      `,
      variables: {
        id
      }
    })
    return response.data.sidebarDestroyMenuItem
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query FIND($id: String!) {
          sidebarFind(id: $id) ${sidebar}
        }
      `,
      variables: {
        id
      }
    })

    return response.data.sidebarFind
  }

  static async list(filter, pagination, orderBy) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST(
          $filter: [FilterInput!]
          $orderBy: String
          $pagination: PaginationInput
        ) {
          sidebarListWithPagination(
            filter: $filter
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows ${sidebar}
          }
        }
      `,
      variables: {
        orderBy: orderBy ? orderBy : 'createdAt',
        filter,
        pagination
      }
    })

    return response.data.sidebarListWithPagination
  }
  static async listAll() {
    const response = await graphqlClient.query({
      query: gql`
        query LIST {
          sidebarList ${sidebar}
        }
      `
    })

    return response.data.sidebarList
  }

  static async arrangeSidebar(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation MOVE($ids: [String!]!) {
          sidebarArrangeMenuItems(ids: $ids)
        }
      `,
      variables: {
        ids
      }
    })

    if (
      response.data.sidebarArrangeMenuItems &&
      response.data.sidebarArrangeMenuItems.status
    ) {
      return response.data.sidebarArrangeMenuItems.result
    } else {
      console.error(response.data.sidebarArrangeMenuItems.error)
      throw response.data.sidebarArrangeMenuItems.error
    }
  }
}
