import _values from 'lodash/values'
import { i18n } from '@/vueI18n'

class Roles {
  static get values() {
    return {
      owner: 'owner',
      admin: 'admin',
      editor: 'editor',
      viewer: 'viewer',
      // auditLogViewer: 'auditLogViewer',
      // iamSecurityReviewer: 'iamSecurityReviewer',
      // entityEditor: 'entityEditor',
      // entityViewer: 'entityViewer',
      usersEditor: 'usersEditor',
      usersViewer: 'usersViewer',
      notificationEditor: 'notificationEditor',
      notificationViewer: 'notificationViewer',
      providerCategoryViewer: 'providerCategoryViewer',
      providerCategoryEditor: 'providerCategoryEditor',
      providerProductViewer: 'providerProductViewer',
      providerProductEditor: 'providerProductEditor',
      providerOrderViewer: 'providerOrderViewer',
      resendCodeAction: 'resendCodeAction',
      providerWalletViewer: 'providerWalletViewer',
      mobileAuctionViewer: 'mobileAuctionViewer',
      mobileAuctionEditor: 'mobileAuctionEditor',
      mobileSidebarViewer: 'mobileSidebarViewer',
      mobileSidebarEditor: 'mobileSidebarEditor',
      mobileSliderViewer: 'mobileSliderViewer',
      mobileSliderEditor: 'mobileSliderEditor',
      settingsEditor: 'settingsEditor',
      settingsViewer: 'settingsViewer'
    }
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) return roleId
    return i18n(`roles.${roleId}.label`)
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) return roleId
    return i18n(`roles.${roleId}.description`)
  }

  static get selectOptions() {
    return _values(this.values).map(value => ({
      id: value,
      value,
      title: this.descriptionOf(value),
      label: this.labelOf(value)
    }))
  }
}

export default Roles
